import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { fetchNotificationsCount } from '../modules/student/api/repository/notifications';
import { notificationsSlice } from '../store/notifications';

const StudentLayout = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      fetchNotificationsCount().then((res) => {
        dispatch(notificationsSlice.actions.setNotificationsCount(res));
      });
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.currentRole === 'coach') {
    return <Navigate to="/coach/schedule" />;
  }

  if (user.currentRole === 'hr') {
    return <Navigate to="/hr/dashboard" />;
  }

  return (
    <div className={`w-full min-h-[100vh] flex flex-col items-center pb-20 theme light-theme`}>
      <Outlet />
    </div>
  );
};

export default StudentLayout;
