import React, { useMemo } from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonColorType = 'main' | 'light';
export type ButtonVariant = 'solid' | 'text' | 'outline';

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  type?: ButtonType;
  isRed?: boolean;
  onClick?: () => void;
  variant?: ButtonVariant;
  colorType?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
};

const LMSButton: React.FC<ButtonProps> = ({
  type = 'button',
  className = '',
  onClick = () => {},
  variant = 'solid',
  colorType = 'main',
  disabled = false,
  loading = false,
  isRed = false,
  children,
  suffix,
  prefix,
}) => {
  const btnClass = useMemo(
    () =>
      `
        flex items-center justify-center space-x-2 text-sm z-10 md:z-0 md:text-base px-3 md:px-[25px] py-2 md:py-3.5 rounded-full
        ${disabled ? '!bg-[#CECECE] cursor-not-allowed' : ''}
        ${variant === 'text' ? 'bg-transparent' : ''}
        ${
          isRed
            ? 'bg-red-500 hover:bg-red-600'
            : variant === 'outline'
            ? 'bg-transparent text-[#253BD1] border border-[#7C89E3] hover:bg-[#181c210f] hover:text-[#4e5155] hover:border-[#181c211a]'
            : 'theme-default-button--active hover:bg-[#0f3ad2]'
        }        
        ${
          loading
            ? 'relative text-transparent'
            : colorType === 'light' && variant === 'outline'
            ? 'text-[#253BD1]'
            : colorType === 'light'
            ? 'bg-white text-black'
            : 'text-white'
        }
        ${className}
      `,
    [disabled, variant, isRed, loading, colorType, className]
  );
  return (
    <button className={btnClass} type={type} disabled={disabled || loading} onClick={onClick}>
      {loading ? (
        <span className="flex items-center justify-center">
          <SpinnerIcon className="animate-spin" />
        </span>
      ) : null}
      <span>{prefix ? prefix : null}</span>
      <span>{children}</span>
      <span>{suffix ? suffix : null}</span>
    </button>
  );
};

export default LMSButton;
