import { Outlet } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import Avatar from 'components/Avatar';
import { useAppSelector } from 'hooks/redux';

import Calendar from '../../components/Calendar';
import Header from '../../components/Header';
import MyCourseTabs from '../../components/MyCourseTabs';
import Title from '../../components/Title';
import { useCalendar } from '../../hooks/calendar';

const MyCourses = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { closestLessons, calendarLessons, studyDays, closestLessonDate, setCurrentDate } =
    useCalendar();

  const dayTime = (): string => {
    const hour = +new Date().getHours();

    if (hour <= 6) {
      return `Доброй ночи, ${user?.fio}`;
    } else if (hour <= 12) {
      return `Доброе утро, ${user?.fio}`;
    } else if (hour <= 18) {
      return `Добрый день, ${user?.fio}`;
    } else {
      return `Добрый вечер, ${user?.fio}`;
    }
  };

  return (
    <>
      <Header />
      <div className="w-full text-current px-4 pt-8 md:pt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_1.8fr_1fr_1fr] gap-5 mb-10">
          <div className="flex flex-col items-center min-h-[400px] p-7 theme-student-card rounded-[20px]">
            <div className="flex items-center justify-center w-[94px] h-[94px] rounded-full bg-blue/20 overflow-hidden">
              <Avatar image={user?.avatar} alt={user?.fio} />
            </div>
            <p className="text-center text-[22px] mt-4">{dayTime()}</p>
            <p className="flex flex-col items-center mt-10 theme-active-text">
              <span className="text-6xl text-current">{closestLessonDate?.getDate()}</span>
              <span className="text-current">
                {closestLessonDate?.toLocaleDateString('ru-RU', { month: 'long' })}
              </span>
            </p>
            {closestLessonDate && <p className="mt-3 theme-text text-center">Ближайшее занятие</p>}
          </div>
          <div className="min-h-[400px] py-6 px-8 theme-student-calendar-card rounded-[20px]">
            <Calendar studyDays={studyDays} setDate={setCurrentDate} lessons={calendarLessons} />
          </div>
          {closestLessons.map((lesson) => (
            <div
              key={lesson.id}
              className="flex flex-col justify-between md:min-h-[400px] p-7 theme-student-card rounded-[20px]"
            >
              <p className="text-sm text-current">{lesson.courseName}</p>

              <div>
                <p className="flex space-x-1 text-sm mt-5 md:mt-0">
                  <span className="px-3 py-[2px] rounded-full theme-badge whitespace-nowrap">
                    {lesson.isPassed ? 'Пройдено' : 'Не пройдено'}
                  </span>
                </p>
                <p className="mt-6 text-[22px]">{lesson.name}</p>
              </div>

              <div className="mt-6">
                <div className="flex items-center space-x-2 mb-2 md:mb-4 theme-default-icon">
                  <CalendarIcon />
                  <p>{lesson.date}</p>
                </div>
                <div className="flex items-center space-x-2 theme-default-icon">
                  <ClockIcon />
                  <p>
                    {lesson.timeFrom.substring(0, 5)} - {lesson.timeTo.substring(0, 5)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Title className="text-center mb-10">Мои курсы</Title>
        <MyCourseTabs className="mb-8 md:mb-10" />

        <Outlet />
      </div>
    </>
  );
};

export default MyCourses;
