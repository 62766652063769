import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { useThemeStatus } from 'hooks/theme';

interface Props {
  title: string;
  simulationId: number;
  children: React.ReactNode;
}

const CollapseSimulationDetail: React.FC<Props> = ({ title, simulationId, children }) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const isLight = useThemeStatus(query);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="theme-student-card rounded-2xl overflow-hidden mb-4">
      <div className="w-full flex flex-row justify-between items-center p-4 md:p-7">
        <button onClick={toggleCollapse} className="text-current text-2xl text-left">
          {title}
        </button>
        <div className="flex flex-row items-center space-x-3">
          <button
            className="flex items-center justify-center w-10 h-10 rounded bg-white/20 theme-text--default"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/coach/simulations/edit-simulation/${simulationId}`);
            }}
          >
            <EditIcon className="w-6 h-6" />
          </button>
          <button
            className="flex items-center justify-center w-10 h-10 rounded bg-white/20 theme-text--default"
            onClick={toggleCollapse}
          >
            <ArrowDownIcon
              className={`w-6 h-6 ${
                isOpen ? 'rotate-180 ease-out duration-700' : 'ease-in duration-500'
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out relative pb-4 md:pb-7 px-4 md:px-7 ${
          isOpen ? 'max-h-[2000px] h-max opacity-100' : 'max-h-[100px] opacity-90'
        }`}
      >
        {children}
        {!isOpen && (
          <div
            className={`absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-b from-transparent ${
              isLight ? 'to-white' : 'to-[#24234b]'
            } pointer-events-none`}
          ></div>
        )}
      </div>
    </div>
  );
};

export default CollapseSimulationDetail;
