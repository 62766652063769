import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import DropdownMenu from 'modules/hr/components/DropdownMenu';

const HeaderMobile = () => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLang = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const navItems = [
    { href: '#about', label: t('landing.nav.about') },
    { href: '#study-programs', label: t('landing.nav.programs') },
    { href: '#appointment', label: t('landing.nav.application') },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]);

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="w-full flex flex-row justify-between items-center bg-white gap-5 relative z-20 p-[20px] pt-[20px] pb-0">
      <Link to="/" className="max-w-[240px] h-auto">
        <img src={require('assets/images/logo.webp')} alt="logo" />
      </Link>

      <div className="z-40">
        <DropdownMenu isOpen={menuOpen} toggleDropdown={toggleDropdown} bgColor="bg-black" />
      </div>

      {menuOpen && (
        <div className="absolute top-[70px] text-bold left-0 w-full bg-white shadow-lg z-20 flex flex-col gap-[20px] items-center py-6">
          {navItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              onClick={handleMenuItemClick}
              className="font-[Raleway] text-[22px] text-bold text-black transition hover:text-black mb-2"
            >
              {item.label}
            </a>
          ))}
          <div className="flex justify-center mt-4">
            <button
              className="font-[Raleway] text-[22px] text-bold text-black transition hover:text-black"
              onClick={() => {
                changeLang('kk');
                handleMenuItemClick();
              }}
            >
              Қаз
            </button>
            <button
              className="font-[Raleway] text-[22px] text-bold text-black transition hover:text-black ml-2"
              onClick={() => {
                changeLang('ru');
                handleMenuItemClick();
              }}
            >
              Рус
            </button>
          </div>
          <NavLink
            to="/auth/login"
            onClick={handleMenuItemClick}
            className="font-[Raleway] text-[22px] text-bold text-black transition hover:text-black mt-4"
          >
            {t('landing.nav.login')}
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
