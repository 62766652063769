import request from 'api/axios-instance';

import { IApplication, IApplicationParams } from '../../entities/Application';
import { IApplicationDao } from '../dao/application';

export const fetchApplications = (page = 1) =>
  request.get('v2/hr/application-forms', { params: { page } }).then((res) => {
    return {
      lastPage: res.data?.meta?.last_page || 1,
      data: res.data.data.map(mapOptionDaoToEntity),
    };
  });

export const fetchApplication = (id: number) =>
  request.get(`v2/hr/application-forms/${id}`).then((res) => mapOptionDaoToEntity(res.data.data));

export const postApplication = (id: number, data: IApplicationParams) =>
  request.post(`v2/hr/application-forms/${id}`, data).then((res) => res.data);

const mapOptionDaoToEntity = (applicationDao: IApplicationDao): IApplication => {
  return {
    id: applicationDao.id,
    name: applicationDao.name,
    surname: applicationDao.surname,
    email: applicationDao.email,
    course: applicationDao.course,
    isWorkedOut: applicationDao.is_worked_out,
    comment: applicationDao.comment,
    createdAt: applicationDao.created_at,
  };
};
