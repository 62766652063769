import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  course_id: string | null;
  group_id: string | null;
  fio: string | null;
  date_to: Date | string | null;
  date_from: Date | string | null;
}

export const useJournalBaseFilter = () => {
  const [query, setQuery] = useSearchParams();

  const fullName = query.get('fio');
  const startDate = query.get('date_from');
  const endDate = query.get('date_to');
  const courseId = query.get('course_id');
  const groupId = query.get('group_id');

  const [filter, setFilter] = usePartialState<Filter>({
    course_id: courseId || null,
    group_id: groupId || null,
    fio: fullName || null,
    date_to: startDate || null,
    date_from: endDate || null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (key && value !== null) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    // query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      course_id: courseId || null,
      group_id: groupId || null,
      fio: null,
      date_to: null,
      date_from: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    // query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
