import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import kk from 'date-fns/locale/kk';
import ru from 'date-fns/locale/ru';

import cl from './date-picker.module.scss';

interface CustomProps {
  label?: string;
  dateFormat?: string;
  error?: string;
  customSelectsRange?: boolean;
}

const DatePickerComponent = DatePicker as unknown as React.ComponentClass<ReactDatePickerProps>;

const LMSDatePicker: React.FC<ReactDatePickerProps & CustomProps> = ({
  customSelectsRange,
  dateFormat = 'dd.MM.yyyy',
  label,
  error,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = useMemo(() => (language === 'kk' ? kk : ru), [language]);

  return (
    <>
      {label ? <p className={cl['date-picker__label']}>{label}</p> : null}
      <DatePickerComponent
        selectsRange={customSelectsRange as undefined}
        locale={locale}
        dateFormat={dateFormat}
        closeOnScroll
        isClearable
        {...rest}
        className={error ? cl['date-picker--error'] : ''}
      />
      {error ? <p className={cl['date-picker__error']}>{error}</p> : null}
    </>
  );
};

export default LMSDatePicker;
