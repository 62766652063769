import { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as CountIcon } from 'assets/icons/count.svg';
import { ReactComponent as FaqIcon } from 'assets/icons/faq.svg';
import { ReactComponent as GameIcon } from 'assets/icons/game.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/library.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-full.svg';
// import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import ChangeRoleButton from 'components/ChangeRoleButton';
import LMSDropdown, { DropdownOption } from 'components/LMSDropdown';
import { useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import NotificationDropdown from '../NotificationDropdown';

const simulationOptions: DropdownOption[] = [
  {
    id: 6,
    title: 'Математический вихрь',
    icon: <TaskIcon className="w-5 h-5" />,
    path: '/student/math-vortex',
  },
  {
    id: 7,
    title: 'Следопыт',
    icon: <SearchIcon className="w-5 h-5" />,
    path: '/student/pathfinder',
  },
  {
    title: 'Lectio',
    id: 8,
    icon: <GameIcon className="w-5 h-5" />,
    path: '/student/simulations',
  },
  {
    title: 'Считай быстро',
    id: 9,
    icon: <CountIcon className="w-5 h-5" />,
    path: '/student/count-quickly',
  },
];

const Header = () => {
  const location = useLocation();
  const windowSize = useWindowSize();
  const { user } = useAppSelector((state) => state.auth);

  const simulationPlaceholder: DropdownOption = {
    id: 6,
    title: 'Симуляции',
    icon: <ListIcon className="w-5 h-5" />,
    path: '/student',
  };

  const menuOptions: DropdownOption[] = useMemo(() => {
    const menu = [
      {
        id: 1,
        title: 'Мое обучение',
        icon: <BookIcon />,
        path: '/student',
      },
      {
        id: 2,
        title: 'Библиотека',
        icon: <LibraryIcon className="w-5 h-5" />,
        path: '/student/library',
      },
      {
        id: 4,
        title: 'Вопрос-ответ',
        icon: <FaqIcon className="w-5 h-5" />,
        path: '/student/faq',
      },
      // {
      //   id: 5,
      //   title: 'Eduson',
      //   icon: <BookIcon />,
      //   path: '/student/eduson',
      // },
    ];

    if (windowSize === 'desktop') {
      return [...menu];
    } else {
      return [
        ...menu,
        {
          id: 3,
          title: 'Все курсы',
          icon: <ListIcon className="w-5 h-5" />,
          path: '/student/courses',
        },
      ];
    }
  }, [windowSize]);

  const [selectedMenu, setSelectedMenu] = useState<DropdownOption>(menuOptions[0]);
  const [selectedSimulation, setSelectedSimulation] =
    useState<DropdownOption>(simulationPlaceholder);

  useEffect(() => {
    const option = menuOptions.find((o) => o.path === location.pathname);
    if (option) {
      setSelectedMenu(option);
    }
    const simulation = simulationOptions.find((o) => o.path === location.pathname);
    if (simulation) {
      setSelectedSimulation(simulation);
    }
  }, [location.pathname]);

  return (
    <div className="w-full theme-text py-7 px-4 border-b border-white/25">
      <div className="flex items-center justify-between mx-auto">
        <NavLink
          to="/student"
          className="max-w-[130px] lg:max-w-[190px] h-auto text-current text-3xl font-semibold"
        >
          <img
            src={require('assets/images/logo.webp')}
            alt="logo"
            className="w-full h-full object-contain object-center"
          />
          {/* {user?.business?.name ? (
            <span className="text-3xl text-current text-center font-semibold">
              {user?.business?.name}
            </span>
          ) : (
            <LogoIcon />
          )} */}
        </NavLink>
        <div className="flex items-center space-x-2 md:space-x-5">
          {windowSize === 'desktop' && (
            <>
              <NavLink
                to="/student/courses"
                className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-header-button rounded-full text-current"
              >
                <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full theme-header-icon theme-border-icon">
                  <ListIcon className="w-5 h-5" />
                </div>
                <p className="hidden md:block">Все курсы</p>
              </NavLink>
              <LMSDropdown options={simulationOptions} position="left">
                <div className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-header-button rounded-full text-current">
                  <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full theme-header-icon theme-border-icon">
                    {selectedSimulation.icon}
                  </div>
                  <p className="hidden md:block">{selectedSimulation.title}</p>
                </div>
              </LMSDropdown>
            </>
          )}
          <LMSDropdown options={menuOptions} position="left">
            <div className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-header-button rounded-full text-current">
              <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full theme-header-icon theme-border-icon">
                {selectedMenu.icon}
              </div>
              <p className="hidden md:block">{selectedMenu.title}</p>
            </div>
          </LMSDropdown>
          <NavLink
            to="/student/profile"
            className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-header-button rounded-full text-current"
          >
            <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full text-black theme-header-icon theme-border-icon">
              <UserIcon />
            </div>
            <p className="hidden md:block max-w-[180px] theme-text--default truncate">
              {user?.fio}
            </p>
          </NavLink>
          <ChangeRoleButton />
          <NotificationDropdown leftPosition="right-0" />
        </div>
      </div>
    </div>
  );
};

export default Header;
