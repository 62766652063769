import {
  ICourseMetaData,
  TCouchInfo,
  TCourseGroupMetaData,
  TCourseMetaData,
  TLessons,
  TModuleMetaData,
} from 'modules/coach/entities/CourseGroupList';
import request from 'api/axios-instance';

import {
  TCouchInfoDao,
  TCourseGroupMetaDataDao,
  TCourseMetaDataDao,
  TLessonsDao,
  TModuleMetaDataDao,
} from '../dao/CoursesGroupListDao';

export const fetchNewCoursesList = (params: Record<number, number>) =>
  request.get('v2/coach/catalog/courses', { params }).then((res) => {
    const courseList: ICourseMetaData[] = res.data.data;
    return courseList;
  });
export const fetchModules = () => {
  return request.get('v2/coach/total-courses/modules').then((res) => {
    const modules: Record<string, never>[] = res.data.data;
    return modules;
  });
};
export const fetchNewGroupList = (id: number) =>
  request.get(`v2/coach/catalog/courses/${id}/groups`).then((res) => {
    const groupList: ICourseMetaData[] = res.data.data;
    return groupList;
  });

export const fetchNewModuleList = (id: number) =>
  request.get(`v2/coach/catalog/courses/${id}/modules`).then((res) => {
    const moduleList: ICourseMetaData[] = res.data.data;
    return moduleList;
  });

export const fetchNewLessonsList = (courseId: number, moduleId: number) =>
  request.get(`v2/coach/catalog/courses/${courseId}/modules/${moduleId}/lessons`).then((res) => {
    const lessonList: ICourseMetaData[] = res.data.data;
    return lessonList;
  });

export const fetchCoursesGroupList = (params: Record<number, number>) =>
  request.get('v1/groups', { params }).then((res) => {
    const groupList: TCourseMetaData[] = res.data.data.map(mapCourseDaoToEntity);
    return groupList;
  });

function mapCourseDaoToEntity(courseDao: TCourseMetaDataDao): TCourseMetaData {
  return {
    id: courseDao.id,
    name: courseDao.name,
    course_type: courseDao.course_type,
    groups: courseDao.groups.map(mapGroupDaoToEntity),
    modules: courseDao.modules.map(mapModulesDaoToEntity),
  };
}

function mapGroupDaoToEntity(groupDao: TCourseGroupMetaDataDao): TCourseGroupMetaData {
  return {
    id: groupDao.id,
    name: groupDao.name,
    lessons: groupDao.group_lessons.map(mapLessonsDaoToEntity),
  };
}

function mapModulesDaoToEntity(moduleDao: TModuleMetaDataDao): TModuleMetaData {
  return {
    id: moduleDao.id,
    courseId: moduleDao.course_id,
    kineScopeModuleId: moduleDao.kine_scope_module_id,
    title: moduleDao.title,
    description: moduleDao.description,
    slug: moduleDao.slug,
  };
}

function mapLessonsDaoToEntity(lessonDao: TLessonsDao): TLessons {
  return {
    id: lessonDao.id,
    position: lessonDao.position,
    testId: lessonDao.test_id,
    name: lessonDao.name,
    auditoriumName: lessonDao.auditorium_name,
    courseName: lessonDao.course_name,
    groupName: lessonDao.group_name,
    lessonDescription: lessonDao.lesson_description,
    programName: lessonDao.program_name,
    videoBroadcastUrl: lessonDao.video_broadcast_url,
    videoUrl: lessonDao.video_url,
    videoYoutubeUrl: lessonDao.video_youtube_url,
    isLast: lessonDao.is_last,
    isPassed: lessonDao.is_passed,
    isTest: lessonDao.is_test,
    homeworkDeadline: lessonDao.homework_deadline,
    date: lessonDao.date,
    timeFrom: lessonDao.time_from,
    timeTo: lessonDao.time_to,
    coach: mapCouchDaoToEntity(lessonDao.coach),
  };
}

function mapCouchDaoToEntity(couchDao: TCouchInfoDao): TCouchInfo {
  return {
    id: couchDao?.id,
    avatar: couchDao?.avatar,
    company: couchDao?.company,
    division: couchDao?.division,
    email: couchDao?.email,
    fullName: couchDao?.full_name,
    gender: couchDao?.gender,
    grade: couchDao?.grade,
    holding: couchDao?.holding,
    occupation: couchDao?.occupation,
    personalType: couchDao?.personal_type,
    positionFamily: couchDao?.position_family,
    positionLevel: couchDao?.position_level,
    positionLevelTop: couchDao?.positionLevel_top,
  };
}
