import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import LMSButton from 'components/LMSButton';
import LMSToggle from 'components/LMSToggle';
import Loader from 'components/Loader';
import { IAttendance, IStudent } from 'modules/coach/api/dao/Attendance.dao';
import { fetchStudentList, setAttendance } from 'modules/coach/api/repository/attendance';
import { useNotification } from 'hooks/notification';

const LessonAttendance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [attendanceInfo, setAttendanceInfo] = useState<IAttendance>();
  const notify = useNotification();

  useEffect(() => {
    fetchStudentList(String(id)).then((res) => {
      setAttendanceInfo(res);
    });
  }, [id, isLoading]);

  const changeStudentStatus = (value: boolean, studentId: number) => {
    const findStudentIndex = attendanceInfo?.students.findIndex(
      (student) => student.id === studentId
    );

    if (findStudentIndex !== undefined) {
      const newStudents = [...attendanceInfo!.students];
      newStudents[findStudentIndex].is_present = value;
      setAttendanceInfo(Object.assign({}, attendanceInfo, { students: newStudents }));
    }
  };

  const checkStudentStatus = (student: IStudent) => {
    return !student.is_present_exists
      ? 'Нет отметки'
      : student.is_present
      ? 'Присутствовал'
      : 'Отсутствовал';
  };

  const checkStudentStatusStyles = (student: IStudent) => {
    const color = !student.is_present_exists
      ? 'text-current'
      : student.is_present
      ? 'text-[#00B341]'
      : 'text-[#FF0000]';
    return `${color} text-center width-full flex-1`;
  };

  const goBack = () => {
    navigate('/coach/schedule');
  };

  const saveAttendance = async () => {
    const students = attendanceInfo!.students.map((student) => ({
      id: student.id,
      is_present: student.is_present,
    }));

    const data = {
      calendar_id: Number(attendanceInfo!.calendar_id),
      students,
    };

    setLoading(true);

    await setAttendance(data)
      .then((res) => {
        notify.success(res.message);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <div className="mt-10">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium theme-default-button--active rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full h-full mt-10 flex flex-col gap-10 theme-student-card p-4 rounded-xl mt-8">
        <div className="flex w-full justify-between ">
          <section className="flex flex-col gap-1">
            <h2 className="text-[32px] text-current font-medium">Посещаемость</h2>
            <p className="text-[18px] text-current">
              Группа: <span className="font-medium">{attendanceInfo?.group_name}</span>
            </p>
            <p className="text-[18px] text-current">
              Урок: <span className="font-medium">{attendanceInfo?.lesson_name}</span>
            </p>
            <p className="text-[18px] text-current">
              Дата:{' '}
              <span className="font-medium">
                {`${attendanceInfo?.date} ${attendanceInfo?.time_from}-${attendanceInfo?.time_to}` ||
                  'Не установлена'}
              </span>
            </p>
          </section>
          <div className={!attendanceInfo?.students?.length ? 'hidden' : ''}>
            <LMSButton
              onClick={() => saveAttendance()}
              className="flex items-center gap-2 text-[14px] font-medium"
            >
              Сохранить
            </LMSButton>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {isLoading && (
            <div className="py-2 px-4">
              <Loader theme="dark" />
            </div>
          )}
          {!isLoading && attendanceInfo?.students?.length ? (
            attendanceInfo?.students.map((student) => (
              <div className="flex items-center justify-between gap-2" key={student.id}>
                <div className="flex items-center gap-2 flex-1">
                  <img
                    className="w-[60px] h-[60px] rounded-full object-cover"
                    src={student.avatar}
                  />
                  <span className="text-current text-[16px]">{student.name}</span>
                </div>
                <span className={checkStudentStatusStyles(student)}>
                  {checkStudentStatus(student)}
                </span>
                <div className="flex-none">
                  <LMSToggle
                    value={student.is_present}
                    onChange={(val) => {
                      changeStudentStatus(val, student.id);
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center text-current">Данных нет</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonAttendance;
