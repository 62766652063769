import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSDragDropContext from 'components/LMSDragDropContext';
import LMSDraggable from 'components/LMSDraggable';
import LMSDroppable from 'components/LMSDroppable';
import {
  deleteCoachTest,
  fetchCoachTest,
  updateCoachTest,
} from 'modules/coach/api/repository/edit-test';
import { fetchLessons } from 'modules/coach/api/repository/lessons';
import { INewLesson } from 'modules/coach/entities/Lessons';
import * as Test from 'modules/coach/entities/Test';
import { getDateTime, getFormattedDateWithTime } from 'modules/coach/utils/dateUtils';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import { CoachCurrentTestDao } from '../../api/dao/Test.dao';
import DropdownSelect from '../../components/DropdownSelect';
import TestQuestionCard from '../../components/TestQuestionCard';
import { Answer } from '../test-create/Answer';

export interface Question {
  id: number;
  name: string;
  answers: Answer[];
  done: boolean;
  position: number;
  isExpand: boolean;
  isNew?: boolean;
}

const TestUpdate = () => {
  const { control, handleSubmit, setValue } = useForm<Test.ICreateTest>();
  const [query] = useSearchParams();
  const { testId } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLastTest, setIsLastTest] = useState<boolean>(false);
  const [Lessons, setLessons] = useState<INewLesson[]>([]);
  const [Test, setTest] = useState<CoachCurrentTestDao>();
  const [lessonName, setLessonName] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [deadlineDate, setDeadlineDate] = useState<Date>(new Date());
  const title = query.get('title') || 'Редактирование теста';

  useEffect(() => {
    fetchLessons()
      .then((res) => {
        setLessons(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchCoachTest(String(testId))
      .then((res) => {
        setTest(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [testId]);

  useEffect(() => {
    if (Test?.id) {
      const date = getDateTime(Test?.deadline.slice(0, 10), Test?.deadline.slice(11, 16)) as
        | Date
        | string;

      const lesson = Lessons.find((l) => l.id === Test?.lesson_id);

      const questions = Test?.questions.map((q) => {
        return {
          id: q.id,
          name: q.name,
          answers: q.answer_options.map((a) => {
            return {
              id: a.id,
              name: a.name,
              isCorrect: a.is_correct,
              position: a.position,
            };
          }),
          done: true,
          isExpand: true,
          position: q.position,
        };
      });

      setValue('name', Test?.name || '');
      setLessonName(lesson!.name);
      setValue('lesson_id', lesson!.id);
      setValue('time_amount', Test?.time_amount);
      setValue('minimum_score_to_pass', Test?.minimum_score_to_pass || 0);
      setValue('attempts_number', Test?.attempts_number);
      setDeadlineDate(new Date(date));
      setIsLastTest(Test?.is_last);
      setQuestions(questions);
    }
  }, [Test]);

  const handleDelete = async () => {
    await deleteCoachTest(String(testId))
      .then(() => {
        goBackToTests();
      })
      .catch((e) => {
        notify.error(e?.response?.data?.message || 'Неизвестная ошибка! Попробуйте позже');
      });
  };

  const { showConfirmBox } = useConfirmBox();

  const handleConfirmation = () => {
    showConfirmBox({
      title: `Тест - ${Test?.name}`,
      message: 'Вы уверены что хотите удалить тест?',
      okVariant: 'light',
      cancelVariant: 'red',
      okText: 'Да',
      cancelText: 'Нет',
      onAccept: () => {
        handleDelete();
      },
      onCancel: () => {
        return;
      },
    });
  };

  const goBackToTests = () => {
    navigate('/coach/tests');
  };

  const addNewQuestion = () => {
    const question: Question = {
      id: Date.now(),
      name: '',
      answers: [],
      done: false,
      isExpand: true,
      position: questions.length + 1,
      isNew: true,
    };

    setQuestions((prev) => [...prev, question]);
  };

  const onQuestionChange = (question: Question) => {
    setQuestions((prev) => prev.map((q) => (q.id === question.id ? question : q)));
  };

  const onQuestionCopy = (question: Question) => {
    setQuestions((prev) => [
      ...prev,
      {
        id: Date.now(),
        name: question.name,
        answers: [...question.answers],
        done: false,
        isExpand: true,
        position: questions.length + 1,
        isNew: true,
      },
    ]);
  };

  const onQuestionOrderChange = (result: DropResult) => {
    if (!result.destination) return;

    const newQuestions = [...questions];
    const [reorderedQuestion] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, reorderedQuestion);
    setQuestions(newQuestions);
  };

  const onSubmit = (formData: Test.ICreateTest) => {
    if (!questions.length) {
      notify.error('Добавьте вопросы');
      return;
    }

    if (!questions.every((q) => q.done)) {
      notify.error('Есть не сохраненные тесты');
      return;
    }

    if (questions.length < 3) {
      notify.error('Для создания теста необходимо более 3-х вопросов');
      return;
    }

    const data = {
      deadline: getFormattedDateWithTime(String(deadlineDate)),
      lesson_id: +(formData.lesson_id || 0),
      is_last: isLastTest,
      name: formData.name,
      attempts_number: formData.attempts_number,
      minimum_score_to_pass: Number(formData.minimum_score_to_pass),
      time_amount: formData.time_amount,
      questions: questions.map((question, index) =>
        mapQuestionsToDto({ ...question, position: index + 1 })
      ),
    };

    updateCoachTest(String(testId), data)
      .then(() => {
        goBackToTests();
      })
      .catch((e) => {
        notify.error(e?.response?.data?.message || 'Неизвестная ошибка! Попробуйте позже');
      });
  };

  const mapQuestionsToDto = (question: Question) => {
    const questionDto = {
      name: question.name,
      position: question.position,
      answers: question.answers.map((answer, index) =>
        mapAnswersToDto({ ...answer, position: index + 1 })
      ),
    };
    if (question.isNew) {
      return questionDto;
    }
    return { id: question.id, ...questionDto };
  };

  const mapAnswersToDto = (answer: Answer) => {
    const answerDto = {
      name: answer.name,
      is_correct: answer.isCorrect,
      position: answer.position,
    };
    if (answer.isNew) {
      return answerDto;
    }
    return { id: answer.id, ...answerDto };
  };

  return (
    <div className="mt-10 lg:mt-0 text-current">
      <div className="mt-4 mb-8">
        <button
          type="button"
          onClick={goBackToTests}
          className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
        >
          <ArrowBack />
          Назад
        </button>

        <div className="flex flex-col md:flex-row md:items-center justify-between mt-8 md:mt-14 space-y-2 md:space-y-0">
          <h2 className="text-[32px] font-normal">{title}</h2>
          <div className="flex flex-row gap-x-2">
            <LMSButton isRed onClick={handleConfirmation}>
              Удалить тест
            </LMSButton>
            <LMSButton onClick={handleSubmit(onSubmit)}>Сохранить тест</LMSButton>
          </div>
        </div>
      </div>

      <div className="flex flex-col p-7 theme-student-card rounded-xl">
        <div className="flex flex-col gap-5">
          <div className="w-full h-fit rounded-[14px]">
            <Controller
              name="name"
              control={control}
              rules={{
                required: { message: 'Введите название теста', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Введите название теста"
                    {...field}
                    className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                    // onChange={(e) => setValue('name', e.target.value)}
                  />
                  {error && <p className="text-red-500">{error.message}</p>}{' '}
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="lesson_id"
                control={control}
                rules={{
                  required: { message: 'Выберите урок', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите урок"
                    value={lessonName}
                    list={
                      Lessons.length > 0 ? Lessons.map(({ id, name }) => ({ id, name: name })) : []
                    }
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setLessonName(name);
                      setValue('lesson_id', id);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="time_amount"
                control={control}
                rules={{
                  required: { message: 'Введите время отведенное на тест', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите время отведенное на тест в минутах"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="minimum_score_to_pass"
                control={control}
                rules={{
                  required: { message: 'Введите проходной процент', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите проходной процент"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="attempts_number"
                control={control}
                rules={{
                  required: { message: 'Введите кол-во попыток', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите кол-во попыток"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between mt-10 p-7 theme-student-card rounded-xl">
        <div className="flex flex-row items-center gap-4">
          <p className="whitespace-nowrap">Срок сдачи:</p>
          <div className="new-group w-full flex flex-row">
            <LMSDatePicker
              selected={deadlineDate}
              placeholderText="Выберите дату"
              locale="ru"
              required
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              onChange={(date) => date !== null && setDeadlineDate(date)}
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] theme-text--active" />
          </div>
        </div>
        <div
          onClick={() => setIsLastTest((prev) => !prev)}
          className="flex flex-row items-center gap-x-4 cursor-pointer"
        >
          <button
            className={`flex items-center justify-center shrink-0 w-6 h-6 rounded ${
              isLastTest ? 'bg-[#58DB66]' : 'bg-white/10 border theme-border--light-black'
            }`}
          >
            <CheckIcon className="w-2.5 h-2.5" />
          </button>
          <span>Заключительный тест?</span>
        </div>
      </div>

      <div className="mt-8 md:mt-12">
        <LMSDragDropContext onDragEnd={onQuestionOrderChange}>
          <LMSDroppable droppableId="questionsList">
            {(outerProvided) => (
              <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                {questions.map((question, index) => (
                  <LMSDraggable
                    key={question.id}
                    draggableId={question.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef}>
                        <TestQuestionCard
                          dragProps={provided.dragHandleProps}
                          number={index + 1}
                          question={question}
                          onDelete={(id) => setQuestions((prev) => prev.filter((q) => q.id !== id))}
                          onCopy={onQuestionCopy}
                          onChange={onQuestionChange}
                        />
                      </div>
                    )}
                  </LMSDraggable>
                ))}

                {outerProvided.placeholder as React.ReactNode}
              </div>
            )}
          </LMSDroppable>
        </LMSDragDropContext>
      </div>

      <button
        className="flex items-center justify-center space-x-2 w-full mt-6 py-4 text-current theme-default-button--active rounded-lg theme-current"
        onClick={addNewQuestion}
      >
        <span className="text-2xl">+</span>
        <span>ДОБАВИТЬ ВОПРОС</span>
      </button>
    </div>
  );
};

export default TestUpdate;
