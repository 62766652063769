import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import LMSButton from 'components/LMSButton';
import Loader from 'components/Loader';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import {
  deleteGroup,
  fetchGroupById,
  tokenGenerate,
  tokenRegenerate,
} from '../../api/repository/groups';
import CourseGroupCard from '../../components/CourseGroupCard';
import StudentInfoModal from '../../components/StudentInfoModal';
import { Group } from '../../entities/Group';

const HrGroupDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();
  const { showConfirmBox } = useConfirmBox();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingButton, setLoadingButton] = useState<boolean>(false);
  const [group, setGroup] = useState<Group | null>(null);
  const [studentId, setStudentId] = useState<number>(0);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    fetchGroupById(+id)
      .then((res) => setGroup(res))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onStudentSelect = (id: number) => {
    setStudentId(id);
  };

  const generateToken = () => {
    if (!id) return;
    setLoadingButton(true);
    tokenGenerate(+id)
      .then((res) => setToken(res))
      .catch((error) => notify.error(String(error)))
      .finally(() => setLoadingButton(false));
  };

  const regenerateToken = () => {
    if (!id) return;
    showConfirmBox({
      message: 'Будет сгенерирована новая ссылка, но старая изменится и старая не будет работать',
      cancelVariant: 'red',
      onAccept: () => {
        setLoadingButton(true);
        tokenRegenerate(+id)
          .then((res) => setToken(res))
          .catch((error) => notify.error(String(error)))
          .finally(() => setLoadingButton(false));
      },
    });
  };

  const getLink = () => {
    const currentToken = token || group?.token;
    const path = `${window.location.origin}/auth/student-login?token=${currentToken}`;
    navigator.clipboard
      .writeText(path)
      .then(() => notify.success('Ссылка скопирована в буфер обмена'))
      .catch((e) => notify.error(String(e)));
  };

  const removeGroup = () => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить группу?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteGroup(Number(id))
          .then(() => {
            notify.success('Группа успешно удалена');
            setTimeout(() => {
              navigate('/hr/groups');
            }, 700);
          })
          .catch((e) => notify.error(e.message));
      },
    });
  };

  if (isLoading) {
    return (
      <div className="p-10">
        <Loader />
      </div>
    );
  }

  if (!group) return <div />;

  return (
    <div className="w-full">
      <div className="block lg:flex lg:flex-col items-start">
        <div className="w-full flex flex-col items-stretch space-y-4 mb-10 md:flex-row md:justify-between md:items-center md:space-y-0">
          <div className="flex flex-row items-center justify-between md:justify-start">
            <button
              type="button"
              onClick={() => navigate('/hr/groups')}
              className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase"
            >
              <ArrowBack />
              назад
            </button>
            <button
              onClick={() => removeGroup()}
              className="w-[55px] h-[55px] flex justify-center items-center p-0 text-current theme-icon-stroke border theme-border--active bg-white bg-opacity-20 cursor-pointer rounded-full md:hidden"
            >
              <TrashIcon />
            </button>
          </div>

          <div className="flex flex-col items-stretch space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:items-center">
            {token || group.token ? (
              <>
                <LMSButton onClick={getLink}>Скопировать ссылку</LMSButton>
                <LMSButton loading={isLoadingButton} onClick={regenerateToken}>
                  Сгенерировать новую ссылку
                </LMSButton>
              </>
            ) : (
              <LMSButton loading={isLoadingButton} onClick={generateToken}>
                Сгенерировать ссылку
              </LMSButton>
            )}
            <LMSButton prefix={<EditIcon />} onClick={() => navigate(`/hr/groups/edit/${id}`)}>
              Редактировать
            </LMSButton>
            <button
              onClick={() => removeGroup()}
              className="hidden w-[55px] h-[55px] justify-center items-center p-0 text-current theme-icon-stroke border theme-border--active bg-white bg-opacity-20 cursor-pointer rounded-full md:flex"
            >
              <TrashIcon />
            </button>
          </div>
        </div>

        <div className="w-full">
          <CourseGroupCard group={group} isList={false} />
        </div>

        {group.students && group.students.length ? (
          <div className="w-full">
            <p className="mt-7 md:mt-10 text-[22px]">Список студентов</p>
            <div className="grid grid-cols-1 lg:grid-cols-[1fr_250px] xl:grid-cols-[1fr_360px] max-w-[1300px] gap-5">
              <div className="mt-6 rounded-2xl bg-studentCardBg overflow-hidden">
                <div className="grid grid-cols-[60px_1fr] py-4 text-white/50 bg-white/10">
                  <p className="text-center">№</p>
                  <p>Студент</p>
                </div>
                {group.students.map((student, index) => (
                  <div
                    key={student.id}
                    className="grid grid-cols-[60px_1fr] items-center py-4 border-b border-white/20 last:border-b-0 cursor-pointer hover:bg-white/5"
                    onClick={() => onStudentSelect(student.id)}
                  >
                    <p className="text-center">{index + 1}</p>
                    <div className="flex items-center space-x-4">
                      <p>{student.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        {studentId ? (
          <StudentInfoModal studentId={studentId} onClose={() => setStudentId(0)} />
        ) : null}
      </div>
    </div>
  );
};

export default HrGroupDetail;
