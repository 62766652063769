import request from 'api/axios-instance';

import { CourseFormData } from '../../entities/Course';

export const createCourse = (data: FormData) => {
  return request.post('v1/management/courses', data).then((res) => res.data);
};

export const editCourse = (id: number, data: FormData) => {
  return request.post(`v1/management/courses/${id}`, data).then((res) => res.data);
};

export const deleteCourse = (id: string) => {
  return request.delete(`v1/management/courses/${id}`).then((res) => res.data);
};
