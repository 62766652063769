import { SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as NoAvatar } from 'assets/icons/no-avatar.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSInput from 'components/LMSInput';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import ru from 'date-fns/locale/ru';
import { changeCouchAvatar, editCouchProfile } from 'modules/coach/api/repository/profile';
import { authSlice } from 'store/auth';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ErrorResponse } from 'entities/Error';

import CustomRadioButton from './../../components/CustomRadioButton';

interface ProfileForm {
  phone: string;
  localCity: string;
}

const EditCoucheProfile = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const [selectedGenderOption, setSelectedGenderOption] = useState<string>('male');
  const [isLoadingSpinner, setLoadingSpinner] = useState(false);
  const [isLoadingForm, setLoadingForm] = useState(false);
  const { handleCommonError } = useHandleError();

  const { control, handleSubmit } = useForm<ProfileForm>({
    defaultValues: {
      phone: user?.phone ? (user?.phone as string) : 'Телефон не указан',
      localCity: user?.locationCity ? (user?.locationCity as string) : 'Город не указан',
    },
  });

  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('.');
    return new Date(`${year}-${month}-${day}`);
  };

  const handleOptionChange = (value: SetStateAction<string>) => {
    setSelectedGenderOption(value);
  };

  const onUploadAvatar = async (files: null | FileList) => {
    if (!files || !files.length || !user) return;

    const file = files[0];

    try {
      const fd = new FormData();
      fd.append('avatar', file);
      setLoadingSpinner(true);
      await changeCouchAvatar(fd).then((res) => {
        if (res.status === 200) {
          notify.success('Фото успешно обновлено');
        }
      });
    } catch {
      notify.error('Не удалось обновить фото');
    } finally {
      setLoadingSpinner(false);
    }

    updateLocalUser(file);
  };

  const updateLocalUser = (file: File) => {
    if (!user) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const preview = event.target?.result as string;
      dispatch(authSlice.actions.userFetchingSuccess({ ...user, avatar: preview }));
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = async (form: ProfileForm) => {
    const data = {
      location_city: form.localCity,
      phone: form.phone,
    };
    try {
      setLoadingForm(true);
      await editCouchProfile(data).then((res) => {
        if (res.status === 200) {
          notify.success('Данные успешно сохранены');
        }
      });
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <div className="w-full mt-20 mb-16 lg:mt-0 md:mb-0">
      <div className="flex flex-col pt-6 md:pt-0 lg:h-[calc(100vh-60px)] lg:overflow-y-auto">
        <div className="flex flex-row justify-center items-end md:justify-between">
          <LMSTitle tag="h4" className="w-full mt-auto text-current text-2xl lg:text-3xl">
            Редактировать профиль
          </LMSTitle>
          <LMSButton
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="fixed bottom-0 left-0 right-0 mt-10 py-4 rounded-none md:max-w-[250px] md:min-w-[250px] md:relative md:rounded-full lg:text-sm lg:px-3"
          >
            СОХРАНИТЬ ИЗМЕНЕНИЯ
          </LMSButton>
        </div>

        <div className="w-full flex flex-col-reverse gap-5 mt-[48px] md:flex-row">
          {isLoadingForm ? (
            <div className="w-full flex p-8">
              <Loader />
            </div>
          ) : (
            <div className="w-full flex flex-col gap-5 p-5 theme-coach-background--gn rounded-[20px] md:p-10 ">
              <LMSInput
                name="fio"
                label="ФИО"
                disabled
                suffix={<EditIcon className="mt-[-18px] text-current" />}
                value={user?.fio ? (user?.fio as string) : 'ФИО не указаны'}
                onChange={(e) => e}
              />

              <div className="w-full flex flex-col gap-5 xl:flex-row">
                <div className="relative w-full flex flex-row items-center edit-profile lg:w-1/2">
                  <span className="absolute top-0 left-[18px] text-current text-[14px] text-opacity-60 font-normal">
                    Дата рождения
                  </span>

                  <LMSDatePicker
                    selected={user?.birthDate ? parseDate(user.birthDate) : null}
                    locale={ru}
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled
                    onChange={(date) => date}
                  />
                  <CalendarIcon className="ml-[-44px] text-current lg:ml-[-30px]" />
                </div>
                <div className="w-1/2 flex flex-row gap-x-8">
                  <CustomRadioButton
                    label="Женщина"
                    value="female"
                    disabled
                    selectedValue={selectedGenderOption}
                    onChange={handleOptionChange}
                  />
                  <CustomRadioButton
                    label="Мужчина"
                    value="male"
                    disabled
                    selectedValue={selectedGenderOption}
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
              <LMSInput
                name="occupation"
                label="Должность/специализация*"
                disabled
                suffix={<EditIcon className="mt-[-18px] text-current" />}
                value={user?.occupation ? (user?.occupation as string) : 'Должность не указана'}
                onChange={(e) => e}
              />

              <div className="w-full flex flex-col gap-5 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: { message: 'Обязателен к заполнению', value: true },
                      maxLength: { message: 'Максимум 11 цифр', value: 11 },
                    }}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                      <LMSInput
                        name="phone"
                        label="Телефон"
                        type="number"
                        onBlur={onBlur}
                        error={error?.message}
                        suffix={<EditIcon className="mt-[-18px] text-current" />}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className="w-full lg:w-1/2">
                  <Controller
                    control={control}
                    name="localCity"
                    rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <LMSInput
                        name="city"
                        label="Город"
                        suffix={<EditIcon className="mt-[-18px] text-current" />}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </div>
              </div>
              <LMSInput
                name="mail"
                label="Почта"
                disabled
                suffix={<EditIcon className="mt-[-18px] text-current" />}
                value={user?.email ? (user?.email as string) : 'Почта не указана'}
                onChange={(e) => e}
              />
            </div>
          )}

          <div className="w-full h-fit flex flex-col items-center p-6 theme-coach-background--gn rounded-[20px] md:max-w-[250px]">
            <span className="pt-2.5 pb-5 text-current font-medium text-xl">Аватар</span>
            {isLoadingSpinner ? (
              <div className="flex p-8">
                <Loader />
              </div>
            ) : (
              <>
                {user?.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Profile photo"
                    className="w-[120px] h-[120px] rounded-full object-cover"
                  />
                ) : (
                  <NoAvatar className="w-[42px] h-[42px] rounded-full lg:w-[60px] lg:h-[60px]" />
                )}
              </>
            )}
            <label className="relative w-full mt-10 py-2 text-center text-current rounded-full border border-white bg-transparent cursor-pointer">
              <span className="text-sm">Загрузить фото</span>
              <input
                type="file"
                className="absolute top-0 w-[1px] h-[1px] opacity-0"
                onChange={(event) => onUploadAvatar(event.target.files)}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCoucheProfile;
