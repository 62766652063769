import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import UploadFile from 'modules/coach/components/UploadFile';
import useFileUpload from 'modules/coach/hooks/multiFileUpload';
import { formatDate } from 'modules/coach/utils/dateUtils';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { createUser } from '../../api/repository/users';
import { fileTypes, genders, userTypes } from '../../constants';
import DropdownSelect from '../DropdownSelect';

type Visibility = {
  password: boolean;
  repeatPassword: boolean;
};

const langs: Option[] = [
  {
    value: 1,
    label: 'Русский',
  },
  {
    value: 2,
    label: 'Қазақша',
  },
  {
    value: 3,
    label: 'English',
  },
];

const langsByID: { [key: number]: string } = {
  1: 'ru',
  2: 'kz',
  3: 'en',
};

const AddNewStudent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notify = useNotification();
  const { uploadedFilePaths, upload } = useFileUpload();
  const { control, handleSubmit, setValue, watch } = useForm<{ [key: string]: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [gender, setGender] = useState<string>();
  const [studentType, setStudentType] = useState<string>();
  const [selectedLangs, setSelectedLangs] = useState<Option[]>([]);
  // const [status, setStatus] = useState<string>();
  const [visibility, setVisibility] = useState<Visibility>({
    password: false,
    repeatPassword: false,
  });

  const handleChangeFiles = async (file: File[]) => {
    setLoading(true);
    setFile(file);
    setIsFileUploading(true);

    try {
      await upload(file);
      setIsFileUploading(false);
    } catch (error) {
      setIsFileUploading(false);
    } finally {
      setLoading(false);
    }
  };

  const createVisibilitySuffix = (field: keyof Visibility) => {
    const IconComponent = visibility[field] ? CloseEyeIcon : OpenEyeIcon;
    return (
      <IconComponent
        className="cursor-pointer ml-[-44px] mt-[14px] text-white lg:ml-[-45px]"
        onClick={() => setVisibility((prevState) => ({ ...prevState, [field]: !prevState[field] }))}
      />
    );
  };

  const onChangeSelectedLang = (id: number, name: string) => {
    if (selectedLangs.some((selectedLang) => selectedLang.value === id)) {
      setSelectedLangs((prevState) =>
        prevState.filter((selectedLang) => selectedLang.value !== id)
      );
    } else {
      setSelectedLangs((prevState) => [...prevState, { value: id, label: name }]);
    }
  };

  const onSubmit = (formData: { [key: string]: string }) => {
    setLoading(true);
    const params: Record<string, string | number | string[] | null> = {};
    if (formData.languages) {
      params.languages = [formData.languages];
    }
    if (uploadedFilePaths?.length) params.document_files = uploadedFilePaths;

    createUser({
      users: [
        {
          ...formData,
          ...params,
          role_id: 4,
        },
      ],
    })
      .then(() => {
        notify.success('Пользователь успешно создан');
        navigate('/hr/users/students');
      })
      .catch((e) => {
        notify.error(e?.response?.data?.message || 'Неизвестная ошибка! Попробуйте позже');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-white text-3xl">
          Добавить слушателя
        </LMSTitle>
      </div>

      <div className="w-full flex flex-col gap-10 p-5 bg-gradient rounded-3xl sm:p-10 mt-10">
        {/* <div className="flex flex-col lg:flex-row justify-between space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
          <p className="text-white text-2xl">Вручную или из файла</p>
          <div className="w-full lg:w-[200px] h-max">
            <UploadFile
              uploading={isFileUploading}
              title="Загрузить файл"
              types={fileTypes}
              isMultiple={true}
              files={file}
              handleChange={handleChangeFiles}
            />
          </div>
        </div> */}

        <div className="flex flex-col space-y-4">
          <p className="text-white text-xl">Основные данные</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="login"
              rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Логин"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <Controller
              control={control}
              name="email"
              rules={{
                required: { message: 'Обязателен к заполнению', value: true },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Некорректный адрес почты',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="E-mail"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <Controller
              control={control}
              name="fio"
              rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Полное имя"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <Controller
              control={control}
              name="location_city"
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Город"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <Controller
              control={control}
              name="phone"
              rules={{
                required: { message: 'Обязателен к заполнению', value: true },
                maxLength: { message: 'Максимум 11 цифр', value: 11 },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Телефон"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <div className="w-full flex flex-row new-user">
              <Controller
                name="birth_date"
                control={control}
                render={({ field }) => (
                  <LMSDatePicker
                    selected={field.value ? new Date(field.value) : null}
                    placeholderText="Дата рождения"
                    locale={ru}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="yyyy.MM.dd"
                    maxDate={new Date()}
                    onChange={(date) => setValue('birth_date', formatDate(date as Date, '-'))}
                  />
                )}
              />
              <CalendarIcon className="ml-[-44px] mt-[14px] text-white lg:ml-[-45px]" />
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          <p className="text-white text-xl">Пароль</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Controller
              control={control}
              name={'password'}
              rules={{
                required: { message: t('error.required'), value: true },
                minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full flex flex-row">
                  <div className="w-full rounded-[14px]">
                    <input
                      autoComplete="new-password"
                      type={visibility['password' as keyof Visibility] ? 'text' : 'password'}
                      placeholder="Введите пароль (мин 6 символов)"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                  {createVisibilitySuffix('password' as keyof Visibility)}
                </div>
              )}
            />

            <Controller
              control={control}
              name={'password_confirmation'}
              rules={{
                required: { message: t('error.required'), value: true },
                minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
                validate: (value) => {
                  if (watch('password') !== value) {
                    return 'Ваши пароли не совпадают';
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full flex flex-row">
                  <div className="w-full rounded-[14px]">
                    <input
                      autoComplete="new-password"
                      type={visibility['repeatPassword' as keyof Visibility] ? 'text' : 'password'}
                      placeholder="Повторите пароль"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                  {createVisibilitySuffix('repeatPassword' as keyof Visibility)}
                </div>
              )}
            />
          </div>
          {/* <div className="flex flex-row justify-start">
            <LMSButton variant="text" className="!p-0">
              Сгенерировать пароль и отправить на E-mail пользователя
            </LMSButton>
          </div> */}
        </div>

        <div className="flex flex-col space-y-4">
          <p className="text-white text-xl">Дополнительные данные</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Controller
              name="gender_id"
              control={control}
              render={({ fieldState: { error } }) => (
                <DropdownSelect
                  notRounded={true}
                  title="Пол"
                  value={gender}
                  list={genders}
                  error={error?.message}
                  onCourseClick={(id, name) => {
                    setValue('gender_id', id.toString());
                    setGender(name);
                  }}
                />
              )}
            />

            <Controller
              name="student_type"
              control={control}
              render={({ fieldState: { error } }) => (
                <DropdownSelect
                  notRounded={true}
                  title="Тип слушателя"
                  value={studentType}
                  list={userTypes}
                  error={error?.message}
                  onCourseClick={(id, name) => {
                    setValue('student_type', id === 1 ? 'inner' : 'external');
                    setStudentType(name);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="iin"
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="number"
                    placeholder="ИИН"
                    {...field}
                    className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                </div>
              )}
            />

            <Controller
              control={control}
              name="languages"
              render={({ fieldState: { error } }) => (
                <DropdownSelect
                  notRounded={true}
                  title="Языки"
                  value={selectedLangs.reduce((acc, curr) => {
                    if (acc) {
                      return acc + `, ${curr.label}`;
                    }
                    return acc + curr.label;
                  }, '')}
                  list={langs}
                  error={error?.message}
                  onCourseClick={(id, name) => onChangeSelectedLang(id, name)}
                />
              )}
            />

            {/* <Controller
              name="status"
              control={control}
              rules={{
                required: { message: 'Выберите статус', value: true },
              }}
              render={({ fieldState: { error } }) => (
                <DropdownSelect
                  notRounded={true}
                  title="Статус"
                  value={status}
                  list={[]}
                  error={error?.message}
                  onCourseClick={(id, name) => {
                    setValue('status', id.toString());
                    setStatus(name);
                  }}
                />
              )}
            /> */}

            <UploadFile
              uploading={isFileUploading}
              title="Удостоверение личности"
              types={fileTypes}
              isMultiple={true}
              files={file}
              handleChange={handleChangeFiles}
            />

            {/* <UploadFile
              uploading={isFileUploading}
              title="Договор записи на курс"
              types={fileTypes}
              isMultiple={true}
              files={file}
              handleChange={handleChangeFiles}
            /> */}
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <LMSButton
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            className="!fixed bottom-0 left-0 right-0 py-4 rounded-none sm:!relative sm:rounded-full"
          >
            ДОБАВИТЬ
          </LMSButton>
        </div>
      </div>
    </div>
  );
};

export default AddNewStudent;
