import React from 'react';
import { NavLink } from 'react-router-dom';
import BaseImage from 'components/BaseImage';

import { Group } from '../../entities/Group';

interface Props {
  group: Group;
  isList?: boolean;
}

const CourseGroupCard: React.FC<Props> = ({ group, isList = true }) => {
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-5 xl:space-x-10 space-y-3 max-w-[1360px] md:space-y-0 p-4 md:p-8 rounded-2xl bg-studentCardBg">
      <div className="md:w-[210px] xl:w-[260px] h-[260px] rounded-xl overflow-hidden shrink-0">
        <NavLink to={`/hr/groups/${group.id}`}>
          <BaseImage image={group.course.imageUrl} />
        </NavLink>
      </div>
      <div className="flex-1 flex flex-col space-y-4 justify-around">
        <NavLink
          to={`/hr/groups/${group.id}`}
          className={`text-white text-xl md:text-3xl font-bold ${
            !isList ? 'pointer-events-none' : ''
          }`}
        >
          {group.course.name}
        </NavLink>
        <div className="text-white/70 text-lg flex justify-between max-w-[200px] w-full">
          <div>{parseDate(group.course.dateFrom)}</div>
          <div>-</div>
          <div>{parseDate(group.course.dateTo)}</div>
        </div>
        <div className="flex justify-between w-full">
          <div>{group.name}</div>
        </div>
        <p className="text-white/50">Группа: {group.students.length} человек</p>
      </div>
    </div>
  );
};

export default CourseGroupCard;
