import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import BaseImage from 'components/BaseImage';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import CoursesFilter from 'modules/hr/components/CoursesFilter';
import Loading from 'modules/student/components/Loading';

import { useCourses } from '../../hooks/courses-list';

const HrCourses = () => {
  const navigate = useNavigate();
  const { isLoading, courses, lastPage, currentPage, setCurrentPage } = useCourses();

  const goToCourseDetail = (id: number) => {
    navigate(`/hr/courses/course/${id}`);
  };

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 bg-white/20 text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <div className="block lg:flex items-start">
      <div className="flex-1">
        <div className="flex flex-row justify-between mb-6 lg:mb-10">
          <h2 className="text-[32px] font-semibold">Все курсы</h2>
          <div className="flex lg:flex-row items-center space-x-4">
            <NavLink
              to="/hr/courses/new-course"
              className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-4 px-5 text-white text-[14px] font-bold uppercase bg-[#26A7EF] rounded-[12px] hover:text-white md:relative md:rounded-[30px]"
            >
              <AddIcon />
              <span className="hidden md:block">НОВЫЙ КУРС</span>
            </NavLink>
          </div>
        </div>

        <div className="block mb-6 lg:hidden">
          <CoursesFilter />
        </div>

        {isLoading && courses.length === 0 ? <Loading /> : null}
        {!isLoading && courses.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-[460px] rounded-[20px] bg-studentCardBg">
            <p className="text-[30px] text-center leading-10 max-w-[420px] mb-14">
              Курсы не найдены. Продолжайте поиск, чтобы найти нужный вам курс
            </p>
            <LMSButton>Продолжить обучение</LMSButton>
          </div>
        ) : null}

        <div className="flex flex-col space-y-5">
          {courses.map((course) => (
            <div
              key={course.id}
              className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-9 p-4 md:p-8 rounded-3xl bg-studentCardBg"
            >
              <div className="relative w-full md:w-[300px] lg:w-[266px] h-[210px] lg:h-[266px] shrink-0 bg-white/20 rounded-2xl overflow-hidden">
                {/* <img
                    className="w-full h-full object-cover"
                    src={course.imageUrl}
                    alt={course.name}
                  /> */}
                <NavLink to={`/hr/courses/course/${course.id}`}>
                  <BaseImage image={course.imageUrl} alt={course.name} />
                </NavLink>
                {course.studyType === 'inner' ? (
                  <div className="absolute top-3 py-3 pl-4 pr-2 bg-green text-white text-sm rounded-r-full">
                    <p>внутренний курс</p>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col flex-1">
                <div className="flex flex-wrap space-x-2">
                  <p className={badgeClass}>{course.dateFrom || 'Не указан'}</p>
                </div>
                <NavLink
                  to={`/hr/courses/course/${course.id}`}
                  className=" flex-1 mt-5 mb-5 lg:mt-10 text-2xl text-white"
                >
                  {course.name}
                </NavLink>
                <div className="flex flex-nowrap md:flex-wrap w-full mt-5 md:mt-0 md:mb-2 md:w-auto space-x-2">
                  <LMSButton
                    className="pl-2 pr-2 md:pl-4 md:pr-4"
                    onClick={() => goToCourseDetail(course.id)}
                  >
                    Перейти к курсу
                  </LMSButton>
                  {/* <div className="flex items-center space-x-4 pr-2 bg-white/20 rounded-full overflow-hidden">
                      <div className="w-7 md:w-14 h-7 md:h-14 rounded-full overflow-hidden">
                        <img className="w-full h-full object-cover" src="" alt="" />
                      </div>
                      <p className="max-w-[130px] text-xs md:text-base truncate">
                        Лебакина Алтынай
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
          ))}

          <LMSPagination
            current={currentPage}
            lastPage={lastPage}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default HrCourses;
