import HeaderMobile from 'components/HeaderMobile';

import About from '../../components/About';
import Application from '../../components/Application';
import LandingFooter from '../../components/LandingFooter';
import LandingHeader from '../../components/LandingHeader';
import Programs from '../../components/Programs';
import Reason from '../../components/Reason';

const Landing = () => {
  return (
    <div className="min-h-screen w-full flex flex-col theme-landing bg-white overflow-x-hidden">
      <div className="hidden lg:block">
        <LandingHeader />
      </div>
      <div className="block lg:hidden">
        <HeaderMobile />
      </div>
      <About />
      <Reason />
      <Programs />
      <Application />
      <div className="mt-20">
        <LandingFooter />
      </div>
    </div>
  );
};

export default Landing;
