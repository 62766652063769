import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import instance from '../../../../api/axios-instance';

const Programs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [coursesCount, setCoursesCount] = React.useState(0);

  const navToAllCourses = () => {
    navigate('/courses');
  };
  const getCoursesCount = async () => {
    const { data } = await instance.get('/v1/count-courses');
    setCoursesCount(data.count);
  };
  useEffect(() => {
    getCoursesCount();
  }, []);
  return (
    <div id="study-programs" className="w-full bg-white relative pt-14 pb-7 z-10">
      <div className="max-w-[1200px] w-[90%] flex flex-col items-start gap-8 mx-auto relative z-10">
        <div className="bg-[#F1F6FF] rounded-lg px-6 py-4">
          <p className="text-xs font-bold text-[#2878FF] uppercase tracking-widest">
            {t('landing.programs.subtitle')}
          </p>
        </div>

        <h2 className="text-4xl font-bold text-[#121D2F]">{t('landing.programs.title')}</h2>

        <div className="max-w-[1118px] w-full flex flex-col items-center gap-8 mx-auto">
          <article className="bg-[#F1F6FF] w-full flex flex-col gap-6 rounded-sm p-6 sm:flex-row">
            <img
              src={require('assets/images/landing/warehouse.png')}
              alt="warehouse"
              className="w-full h-[174px] object-cover object-center rounded sm:w-[200px] sm:object-contain"
            />

            <div className="flex flex-col justify-between flex-1">
              <div className="flex flex-col gap-1">
                <p className="text-lg font-semibold text-[#121D2F]">
                  {t('landing.programs.course1.title')}
                </p>
                <div className="text-sm font-medium text-[#121D2F]">
                  <p>{t('landing.programs.course1.text1')}</p>
                  <p>{t('landing.programs.course1.text2')}</p>
                  <p>{t('landing.programs.course1.text3')}</p>
                  <p> - {t('landing.programs.course1.text4')}</p>
                  <p> - {t('landing.programs.course1.text5')}</p>
                </div>
              </div>
            </div>
          </article>

          <article className="bg-[#F1F6FF] w-full flex flex-col gap-4 rounded-sm p-6 sm:flex-row">
            <img
              src={require('assets/images/landing/mission.png')}
              alt="idea"
              className="w-full h-[174px] object-cover object-center rounded sm:w-[200px] sm:object-contain"
            />

            <div className="flex flex-col justify-between flex-1">
              <div className="flex flex-col gap-1">
                <p className="text-lg font-semibold text-[#121D2F]">
                  {t('landing.programs.course2.title')}
                </p>
                <div className="text-sm font-medium text-[#121D2F]">
                  <p>{t('landing.programs.course2.text1')}</p>
                  <p>{t('landing.programs.course2.text2')}</p>
                  <p>{t('landing.programs.course2.text3')}</p>
                  <p>{t('landing.programs.course2.text4')}</p>
                </div>
              </div>
            </div>
          </article>
        </div>

        <button className="bg-[#FCA311] rounded-md p-2 self-center" onClick={navToAllCourses}>
          <p className="text-2xl font-bold text-white">{t('landing.actions.allCourses')}</p>
        </button>
        <p style={{ color: 'black' }} className="text-2xl font-bold self-center">
          {t('landing.actions.allCoursesCount', { coursesCount })}
        </p>
      </div>

      <div className="w-[400px] h-[400px] rounded-[130px] bg-[#F0F6FF] rotate-[45deg] absolute -top-[280px] -right-[60px] z-1"></div>
    </div>
  );
};

export default Programs;
