import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { fetchSimulations } from 'modules/coach/api/repository/simulations';
import { ISimulation, ISimulationDetail } from 'modules/coach/entities/Simulation';
import { useNotification } from 'hooks/notification';

import LectioCongrats from '../../components/LectioCongrats';
import LectioResult from '../../components/LectioResult';
import LectioSelectCategory from '../../components/LectioSelectCategory';
import LectioTest from '../../components/LectioTest';
import LectioWelcome from '../../components/LectioWelcome';

const Simulations = () => {
  const notify = useNotification();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [simulations, setSimulations] = useState<ISimulation[]>([]);
  const [simulation, setSimulation] = useState<ISimulationDetail | null>(null);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const handleChangeSimulation = useCallback(
    (selectedSimulation: ISimulationDetail) => {
      setSimulation(selectedSimulation);
      setStep(step + 1);
    },
    [step]
  );

  const handleExit = () => navigate('/student');

  const renderStep = useMemo(() => {
    if (step > 2 && simulation) {
      switch (step) {
        case 3:
          return (
            <LectioWelcome
              title={`Lectio ${simulation.categoryName}`}
              desc={simulation.target}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 4:
          return (
            <LectioWelcome
              title="Описание"
              desc={simulation.description}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 5:
          return (
            <LectioWelcome
              title="Инструкция"
              desc={simulation.instruction}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        // case 6:
        //   return (
        //     <LectioWelcome
        //       title="Описание категории"
        //       desc={simulation.categoryDescription}
        //       buttonLabel="Далее"
        //       nextStep={nextStep}
        //     />
        //   );
        case 6:
          return (
            <LectioWelcome
              title="Вопросы"
              desc={simulation.questions}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 7:
          return (
            <LectioWelcome
              title="Описание таймера"
              desc={simulation.timerDescription}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 8:
          return (
            <LectioWelcome
              title="Описание количество попыток"
              desc={simulation.tryDescription}
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 9:
          return (
            <LectioWelcome
              title="Описание результата"
              desc={simulation.resultsDescription}
              nextStep={nextStep}
            />
          );
        case 10:
          return (
            <LectioTest
              questions={simulation.lectioQuestions}
              timer={Math.ceil(simulation.seconds / simulation.lectioQuestions.length)}
              nextStep={nextStep}
              setCountCorrectAnswersForResult={(count: number) => setCountCorrectAnswers(count)}
            />
          );
        case 11:
          return (
            <LectioCongrats
              countCorrectAnswers={countCorrectAnswers}
              countWrongAnswers={simulation.lectioQuestions.length - countCorrectAnswers}
              nextStep={nextStep}
              tryAgain={() => setStep(10)}
            />
          );
        case 12:
          return <LectioResult simulation={simulation} />;
      }
    } else {
      switch (step) {
        case 1:
          return (
            <LectioWelcome
              title={`Добро пожаловать в игру "Lectio"`}
              desc="Цель игры научить вас различать латиницу, чтобы вы могли правильно произносить и различать различные названия на латинице, особенно те, которые имеют схожее написание. Это поможет вам уверенно работать с товарами или документами, где важно различать похожие латинские названия."
              buttonLabel="Далее"
              nextStep={nextStep}
            />
          );
        case 2:
          return (
            <LectioSelectCategory
              simulations={simulations}
              selectSimulation={handleChangeSimulation}
            />
          );
      }
    }
  }, [step, simulation]);

  useEffect(() => {
    setIsLoading(true);
    fetchSimulations()
      .then((res) => {
        setSimulations(res.simulations);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-start justify-center m-auto">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full min-h-[100vh] relative">
      <div className="w-full h-full p-10 m-auto">{renderStep}</div>
      <button
        type="button"
        className="h-[40px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6]/50 rounded-[14px] outline-none text-black absolute top-5 right-5"
        onClick={handleExit}
      >
        Выйти из игры
      </button>
    </div>
  );
};

export default Simulations;
