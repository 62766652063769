import { useEffect, useState } from 'react';
import {
  fetchNewCoursesList,
  fetchNewGroupList,
  fetchNewLessonsList,
  fetchNewModuleList,
} from 'modules/coach/api/repository/courses-group-list';
import useHandleError from 'hooks/handleError';
import { ErrorResponse } from 'entities/Error';

import { ICourseMetaData } from '../entities/CourseGroupList';

export const useCoursesListData = (
  selectedCourseId?: number,
  courseId?: number,
  moduleId?: number
) => {
  const [coursesList, setCoursesList] = useState<ICourseMetaData[]>([]);
  const [groups, setGroupsList] = useState<ICourseMetaData[]>([]);
  const [modules, setModules] = useState<ICourseMetaData[]>([]);
  const [lessons, setLessons] = useState<ICourseMetaData[]>([]);
  const { handleCommonError } = useHandleError();

  useEffect(() => {
    const params = {
      by_this_coach: 1,
      course_id: selectedCourseId ? selectedCourseId : null,
    };

    fetchNewCoursesList(params)
      .then((res) => {
        setCoursesList(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, [selectedCourseId]);

  useEffect(() => {
    if (!courseId) return;

    fetchNewModuleList(courseId)
      .then((res) => {
        setModules(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });

    fetchNewGroupList(courseId)
      .then((res) => {
        setGroupsList(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, [courseId]);

  useEffect(() => {
    if (!courseId || !moduleId) return;

    fetchNewLessonsList(courseId, moduleId)
      .then((res) => {
        setLessons(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, [moduleId]);

  return { coursesList, groups, modules, lessons };
};
