import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';
import { ISupportQuestionsData } from 'modules/landing/api/dao/support-questions';
import { useNotification } from 'hooks/notification';

import { sendSupportQuestions } from '../../api/repository/support-questions';

const Application = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { control, handleSubmit } = useForm<ISupportQuestionsData>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (values: ISupportQuestionsData) => {
    try {
      setIsLoading(true);
      await sendSupportQuestions(values);
      notify.success(t('landing.application.messages.success'));
    } catch {
      notify.error(t('landing.application.messages.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="appointment" className="w-full relative pt-14">
      <div className="max-w-[1042px] w-[90%] flex flex-col items-center gap-8 mx-auto relative z-10">
        <div className="bg-[#F1F6FF] rounded-lg px-6 py-4">
          <p className="text-xs font-bold text-[#2878FF] uppercase tracking-widest">
            {t('landing.application.subtitle')}
          </p>
        </div>

        <h2 className="text-4xl font-bold text-[#121D2F]">{t('landing.application.title2')}</h2>

        <article className="w-full flex flex-col gap-6 rounded-[32px] p-5 shadow-20xl overflow-hidden relative sm:flex-row">
          <form className="w-full h-full flex flex-col gap-5 rounded-2xl border border-[#D9D9D9] p-6 sm:w-[320px] relative z-10">
            <div className="w-full h-fit flex flex-col gap-2">
              <label className="text-base text-black">{t('landing.application.form.name')}*</label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: t('error.required'), value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full flex flex-col gap-2">
                    <input
                      type="text"
                      placeholder={t('landing.application.form.name')}
                      {...field}
                      className={`w-full h-[40px] flex items-center px-4 text-black border rounded-lg outline-none ${
                        error ? 'border-red-500' : 'border-[#D9D9D9]'
                      }`}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit flex flex-col gap-2">
              <label className="text-base text-black">
                {t('landing.application.form.surname')}*
              </label>
              <Controller
                name="surname"
                control={control}
                rules={{
                  required: { message: t('error.required'), value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder={t('landing.application.form.surname')}
                      className={`w-full h-[40px] flex items-center px-4 text-black border rounded-lg outline-none ${
                        error ? 'border-red-500' : 'border-[#D9D9D9]'
                      }`}
                      {...field}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit flex flex-col gap-2">
              <label className="text-base text-black">{t('landing.application.form.phone')}*</label>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: { message: t('error.required'), value: true },
                  pattern: {
                    value: /^\+?[1-9]\d{10,14}$/,
                    message: 'Неверный формат телефона',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Номер телефона не может содержать больше 15 символов',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full flex flex-col gap-2">
                    <input
                      type="tel"
                      placeholder={t('landing.application.form.phone')}
                      {...field}
                      className={`w-full h-[40px] flex items-center px-4 !text-black border rounded-lg outline-none ${
                        error ? 'border-red-500' : 'border-[#D9D9D9]'
                      }`}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit flex flex-col gap-2">
              <label className="text-base text-black">Email</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { message: t('error.required'), value: true },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Неверный формат почты',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full flex flex-col gap-2">
                    <input
                      type="email"
                      placeholder="Email"
                      {...field}
                      className="w-full h-[40px] flex items-center px-4 !text-black border rounded-lg outline-none border-[#D9D9D9]"
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit flex flex-col gap-2">
              <label className="text-base text-black">
                {t('landing.application.form.selectedCourse')}
              </label>
              <Controller
                name="question"
                control={control}
                rules={{
                  required: { message: t('error.required'), value: true },
                  maxLength: {
                    value: 250,
                    message: t('Максимальное количество символов: 250', { length: 250 }),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full">
                    <textarea
                      rows={2}
                      placeholder={t('landing.application.form.selectedCourse')}
                      className="w-full flex items-center px-4 py-2 !text-black border border-[#D9D9D9] rounded-lg outline-none"
                      {...field}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>

            <button
              type="submit"
              className="w-full h-[40px] bg-[#3AE000] rounded-lg flex items-center justify-center px-3 text-base text-black
              transition-all duration-300 ease-in-out
              hover:bg-[#32C200] hover:scale-105
              active:bg-[#28A800] active:scale-100
              focus:outline-none focus:ring-2 focus:ring-[#3AE000] focus:ring-opacity-50"
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <SpinnerIcon className="animate-spin" />
                </span>
              ) : null}
              {t('landing.application.form.submit')}
            </button>
          </form>

          <div className="flex flex-1 items-center justify-center relative z-10">
            <p className="max-w-[480px] text-2xl font-medium text-[#121D2F] text-center">
              {t('landing.application.callbackText')}
            </p>
          </div>

          <div className="w-[330px] h-[330px] rounded-full landing-circle absolute top-[50%] right-1/4 rotate-[80deg] -translate-y-1/2"></div>
          <div className="w-[96px] h-[96px] rounded-full landing-circle-mini absolute -top-[48px] -right-[48px]"></div>
          <div className="w-[96px] h-[96px] rounded-full landing-circle-mini absolute top-14 right-40"></div>
        </article>
      </div>
    </div>
  );
};

export default Application;
