import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-dark.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSInput from 'components/LMSInput';
import LMSRadioButton from 'components/LMSRadioButton';
import LMSSelect from 'components/LMSSelect';
import { t } from 'i18next';
import { useCalendar } from 'modules/student/hooks/calendar';
import { authSlice } from 'store/auth';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ErrorResponse } from 'entities/Error';

import {
  changeAvatar,
  editProfile,
  fetchProfileCities,
  fetchProfileGender,
} from '../../api/repository/profile';

interface ProfileForm {
  avatar: string;
  phone: string;
  location_city_id: number | null;
  location_city: string;
  fio: string;
  birth_date: string;
  gender_id: number;
  email: string;
}

type CitiesDataType = {
  id: number;
  name: string;
};

type GendersDataType = {
  id: number;
  name: string;
};

const EditProfile = () => {
  const notify = useNotification();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { handleCommonError } = useHandleError();
  const {} = useCalendar();
  const [genders, setGenders] = useState<GendersDataType[]>([]);
  const [cities, setCities] = useState<CitiesDataType[]>([]);
  const [email, setEmail] = useState<string>('');

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isLoading },
  } = useForm<ProfileForm>({
    defaultValues: {
      phone: '',
      location_city_id: null,
      location_city: '',
      fio: '',
      birth_date: '',
      gender_id: 0,
    },
  });

  useEffect(() => {
    const getFetchData = async () => {
      try {
        const { data: genderData } = await fetchProfileGender();
        const { data: cititesData } = await fetchProfileCities();
        setGenders(genderData.data);
        setCities(cititesData.data);
        if (user && user.email) {
          setEmail(user.email);
        }
      } catch (err) {
        const error = err as ErrorResponse;
        console.log(error, 'error');
        notify.error('Ошибка при загрузке данных о гендерах');
      }
    };

    getFetchData();
  }, []);

  useEffect(() => {
    if (user) {
      const genderId = genders.find((g) => g.name === user.gender)?.id;

      reset({
        phone: user.phone || '',
        location_city_id: user.location_city_id || null,
        location_city: user.location_city || '',
        fio: user.fio || '',
        birth_date: user.birth_date || '',
        gender_id: genderId,
        email,
      });
    }
  }, [user, reset, genders, email]);

  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('.');
    return new Date(`${year}-${month}-${day}`);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const formatedSubmitDate = (date: string) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }

    const convertDate = date.replaceAll('.', '-');
    const [day, month, year] = convertDate.split('-');
    const rearrangedDateString = `${year}-${month}-${day}`;

    return rearrangedDateString;
  };

  const onUploadAvatar = async (files: null | FileList) => {
    if (!files || !files.length || !user) return;

    try {
      const file = files[0];
      const fd = new FormData();
      fd.append('avatar', file);
      await changeAvatar(fd);
      notify.success('Аватар успешно обновлен');
      updateLocalUser(file);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  const updateLocalUser = (file: File) => {
    if (!user) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const preview = event.target?.result as string;
      dispatch(authSlice.actions.userFetchingSuccess({ ...user, avatar: preview }));
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = async (form: ProfileForm) => {
    const data = {
      location_city_id: Number(form.location_city_id),
      phone: form.phone,
      fio: form.fio,
      birth_date: formatedSubmitDate(form.birth_date),
      gender_id: form.gender_id,
      ...(user?.email !== form.email && { email: form.email }),
    };

    try {
      await editProfile(data);
      if (user) {
        dispatch(
          authSlice.actions.userFetchingSuccess({
            ...user,
            ...data,
          })
        );
      }
      notify.success('Данные успешно сохранены');
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  if (!user) return <div />;

  return (
    <div className="mt-12">
      <div className="flex justify-between items-end">
        <div>
          <h2 className="text-2xl md:text-[32px]">Редактировать профиль</h2>
          <p className="text-base md:text-lg ">Данный профиль является корпоративным</p>
        </div>
        <LMSButton loading={isLoading} className="hidden md:flex" onClick={handleSubmit(onSubmit)}>
          СОХРАНИТЬ ИЗМЕНЕНИЯ
        </LMSButton>
      </div>
      <div className="flex flex-col-reverse md:grid grid-cols-[1fr_250px] gap-6 mt-6">
        <div className="flex-1 p-5 md:p-10 rounded-2xl theme-student-card">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="fio"
              rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <LMSInput
                  type="text"
                  name="fio"
                  label="ФИО"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <div className="w-full flex flex-row edit-profile">
              <Controller
                name="birth_date"
                control={control}
                rules={{
                  required: { message: 'Выберите дату рождения', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full">
                    <div className="flex text-white bg-transparent">
                      <LMSDatePicker
                        selected={field.value ? parseDate(field.value) : null}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Дата рождения"
                        dateFormat="dd.MM.yyyy"
                        onChange={(date) => setValue('birth_date', date ? formatDate(date) : '')}
                      />
                      <CalendarIcon className="ml-[-44px] mt-[14px] text-black" />
                    </div>
                    {error && <div className="text-red-500 mt-2">{error.message}</div>}
                  </div>
                )}
              />
            </div>
            <div className="hidden xl:block"></div>

            <Controller
              control={control}
              rules={{
                required: { message: 'Выберите пол', value: true },
              }}
              name="gender_id"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className="flex flex-col gap-4">
                  <div className="flex space-x-8">
                    {genders.map((gender) => (
                      <label key={gender.id} className="flex items-center space-x-2 cursor-pointer">
                        <LMSRadioButton checked={Number(value) === gender.id} />
                        <input
                          type="radio"
                          className="hidden"
                          checked={Number(value) === gender.id}
                          onChange={() => onChange(gender.id)}
                        />
                        <span className="text-sm md:text-base">{gender.name}</span>
                      </label>
                    ))}
                  </div>
                  {error && <div className="text-red-500 mt-2">{error.message}</div>}
                </div>
              )}
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-5">
            <Controller
              control={control}
              name="phone"
              rules={{
                required: { message: 'Обязателен к заполнению', value: true },
                pattern: {
                  value: /^\+?[1-9]\d{10,14}$/,
                  message: 'Неверный формат телефона',
                },
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <LMSInput
                  name="phone"
                  label="Телефон"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="location_city_id"
              rules={{
                required: { message: t('error.required'), value: true },
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <LMSSelect
                  name="location_city_id"
                  placeholder="Выберите город"
                  options={cities ? cities.map((city) => ({ label: city.name, id: city.id })) : []}
                  required
                  value={
                    cities.find((city) => city.id === value)
                      ? {
                          label: cities.find((city) => city.id === value)?.name,
                          id: value,
                        }
                      : null
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      const selectedValue = newValue as CitiesDataType;
                      onChange(selectedValue.id);
                    }
                  }}
                  onBlur={onBlur}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div className="mt-9">
            <Controller
              control={control}
              rules={{
                required: { message: 'Обязателен к заполнению', value: true },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Неверный формат почты',
                },
              }}
              name="email"
              defaultValue={user?.email || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <LMSInput
                    name="email"
                    label="Почта"
                    value={value || ''}
                    onChange={(val) => {
                      setEmail(val);
                      onChange(val);
                    }}
                  />
                  {error && <div className="text-red-500 mt-2">{error.message}</div>}
                </div>
              )}
            />
          </div>
        </div>
        <div className="shrink-0 flex flex-col items-center h-fit w-full xs:w-[250px] p-6 rounded-2xl theme-student-card">
          <p className="mb-5 text-2xl text-center">Аватар</p>

          <div className="w-28 h-28 mb-10 rounded-full overflow-hidden">
            {user?.avatar ? (
              <img className="w-full h-full object-cover" src={user?.avatar} alt={user?.fio} />
            ) : (
              <img src={require('../../../../assets/images/empty-avatar.png')} alt="" />
            )}
          </div>

          <label className="relative w-full py-2 text-center rounded-full border border-solid theme-input bg-transparent cursor-pointer">
            <span className="text-sm">Загрузить фото</span>
            <input
              type="file"
              accept="image/*"
              className="absolute t-0 w-[1px] h-[1px] opacity-0"
              onChange={(event) => onUploadAvatar(event.target.files)}
            />
          </label>
        </div>
      </div>
      <div className="mt-6">
        <LMSButton
          loading={isLoading}
          className="md:hidden w-full"
          onClick={handleSubmit(onSubmit)}
        >
          СОХРАНИТЬ ИЗМЕНЕНИЯ
        </LMSButton>
      </div>
    </div>
  );
};

export default EditProfile;
