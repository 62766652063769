import { Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { useThemeStatus } from 'hooks/theme';

const Auth = () => {
  const { isLoading } = useAppSelector((state) => state.auth);
  const [query] = useSearchParams();

  const isLight = useThemeStatus(query);

  if (isLoading) {
    return <span>Loading....</span>;
  }

  return (
    <div
      className={`w-full min-h-[100dvh] flex justify-center items-center px-3 bg-no-repeat bg-cover theme `}
      //   ${
      //   isLight && 'light-theme'
      // }
    >
      <Outlet />
    </div>
  );
};

export default Auth;
