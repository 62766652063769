import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { ErrorResponse, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/add-blue.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSInput from 'components/LMSInput';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { toolbarCourseOptions } from 'modules/coach/constants';
import { deleteCourse, editCourse } from 'modules/hr/api/repository/create-course';
import { useConfirmBox } from 'hooks/confirm-box';
import { useFormatDate } from 'hooks/format-date';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { currencies, currencyShortNames } from '../../constants';
import { CourseFormData, ICourseDetail, INewCourseCoach } from '../../entities/Course';
import DropdownSelect from '../DropdownSelect';

const EditCourse = ({ course, coaches }: { course: ICourseDetail; coaches: Option[] }) => {
  const { id } = useParams();
  const { control, handleSubmit, setValue, watch } = useForm<CourseFormData>({
    defaultValues: {
      name: course.name,
      description: course.description,
      price: Number(course.price),
      duration_in_hours: course.durationInHours,
    },
  });
  const notify = useNotification();
  const navigate = useNavigate();
  const { toDateMethodReadableString } = useFormatDate();
  const { showConfirmBox } = useConfirmBox();

  const [imageFile, setImageFile] = useState<File | null>();
  const [imagePreview, setImagePreview] = useState<string>();
  const [existingImage, setExistingImage] = useState<string>('');
  const [hasCertificate, setHasCertificate] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [selectedCoaches, setSelectedCoaches] = useState<Option[]>([]);
  const [description, setDescription] = useState<string>('');
  const [minDate, setMinDate] = useState<string>('');
  const [maxDate, setMaxDate] = useState<string>('');

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const validateFormData = (formData: CourseFormData): string[] => {
    const errors: string[] = [];

    if (!imageFile && !existingImage) errors.push('Обложка курса обязательна');
    if (!formData.name?.trim()) errors.push('Название курса обязательно');
    if (!formData.description?.trim()) errors.push('Описание курса обязательно');
    if (!selectedCoaches.length) errors.push('Выберите хотя бы одного тренера');
    if (!formData.date_from) errors.push('Выберите дату начала');
    if (!formData.date_to) errors.push('Выберите дату завершения');
    if (
      formData.date_from &&
      formData.date_to &&
      new Date(formData.date_from) > new Date(formData.date_to)
    ) {
      errors.push('Дата начала не может быть позже даты завершения');
    }

    return errors;
  };

  const onChangeSelectedCoaches = (id: number, name: string) => {
    const isCoachSelected = selectedCoaches.some((coach) => coach.value === id);
    const updatedCoaches = isCoachSelected
      ? selectedCoaches.filter((coach) => coach.value !== id)
      : [...selectedCoaches, { value: id, label: name }];

    setSelectedCoaches(updatedCoaches);

    const coachesArray: INewCourseCoach[] = updatedCoaches.map((coach) => ({
      coach_id: coach.value,
    }));

    setValue('coaches', coachesArray);
  };

  const coachesString = useMemo(
    () => selectedCoaches.map((coach) => coach.label).join(', '),
    [selectedCoaches]
  );

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = useCallback(() => {
    setValue('has_certificate', course.hasCertificate ? 1 : 0);
    setHasCertificate(course.hasCertificate ? 1 : 0);
    setValue('description', course.description);
    setDescription(course.description);

    if (course.dateFrom) {
      setValue('date_from', formatDate(toDateMethodReadableString(course.dateFrom)));
    }
    if (course.dateTo) {
      setValue('date_to', formatDate(toDateMethodReadableString(course.dateTo)));
    }
    if (course.coaches) {
      setSelectedCoaches(
        course.coaches.map((coach) => ({ value: coach.id, label: coach.fullName }))
      );
    }
    if (course.imageUrl) {
      setExistingImage(course.imageUrl);
      setImagePreview(course.imageUrl);
    }
  }, [course, setValue, toDateMethodReadableString]);

  const handleImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setExistingImage('');
    }
  }, []);

  const onSubmit = async (data: CourseFormData) => {
    const formData = new FormData();

    try {
      const validationErrors = validateFormData(data);
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => notify.error(error));
        return;
      }

      if (imageFile) {
        formData.append('image_url', imageFile);
      }

      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (key === 'coaches' && Array.isArray(value)) {
            value.forEach((coach, index) => {
              Object.entries(coach).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${index}][${subKey}]`, String(subValue));
              });
            });
          } else if (typeof value === 'object' && !Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, String(value));
          }
        }
      });

      await editCourse(Number(id), formData);

      notify.success('Курс успешно отредактирован');

      await navigate('/hr/courses');
    } catch (err) {
      const error = err as ErrorResponse;
      console.log(error, 'error');
      notify.error(
        'Не удалось отредактировать курс, повторите еще раз или обратитесь в службу поддержки'
      );
    }
  };

  const goBackToCourses = () => {
    navigate('/hr/courses');
  };

  const handleDelete = async () => {
    await deleteCourse(String(id))
      .then(() => {
        goBackToCourses();
        notify.success('Курс успешно удален');
      })
      .catch((e) => {
        notify.error(e?.response?.data?.message || 'Неизвестная ошибка! Попробуйте позже');
      });
  };

  const handleConfirmation = () => {
    showConfirmBox({
      title: `Курс - ${course?.name}`,
      message: 'Вы уверены что хотите удалить курс?',
      okVariant: 'light',
      cancelVariant: 'red',
      okText: 'Да',
      cancelText: 'Нет',
      onAccept: () => {
        handleDelete();
      },
      onCancel: () => {
        return;
      },
    });
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'date_from') {
        setMinDate(value.date_from ? value.date_from.toString() : '');
      }
      if (name === 'date_to') {
        setMaxDate(value.date_to ? value.date_to.toString() : '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-white text-3xl">
          Редактирование курса
        </LMSTitle>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-10 p-5 bg-gradient rounded-3xl sm:p-10">
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5">Основные данные</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-4">
              <Controller
                name="image_url"
                control={control}
                rules={{
                  required: !imagePreview && { message: 'Добавьте обложку курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="row-span-3">
                    <div
                      className={`flex w-full h-full max-h-[219px] text-white bg-[#070b20] border border-0.5 border-dashed rounded-[14px] overflow-hidden outline-none ${
                        error ? 'border-red-500' : 'border-blue'
                      }`}
                    >
                      <label htmlFor="image" className="flex flex-1 cursor-pointer">
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="preview"
                            className="w-full h-full object-cover object-center"
                          />
                        ) : (
                          <div className="flex flex-col flex-1 items-center justify-center py-3 px-6">
                            <PlusIcon className="mb-4" />
                            <p>Добавить обложку курса</p>
                          </div>
                        )}
                      </label>
                      <input
                        id="image"
                        type="file"
                        accept="image/jpeg, image/png"
                        {...field}
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <p className="lg:hidden text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: 'Введите название курса', value: true },
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      name="name"
                      baseViewPlaceholder
                      placeholder="Название курса"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="description"
                control={control}
                render={() => (
                  <ReactQuill
                    value={description}
                    placeholder={'Описание курса'}
                    modules={{
                      toolbar: toolbarCourseOptions,
                    }}
                    onChange={(content) => {
                      setValue('description', content);
                      setDescription(content);
                    }}
                  />
                )}
              />
            </div>

            <div className="hidden lg:grid grid-cols-2 gap-6">
              <p className="text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5 mt-[50px] md:mt-[40px] sm:mt-[100px]">
              Дополнительные данные
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 grid-rows-2 gap-6">
              <div className="w-full flex column new-lesson">
                <Controller
                  name="date_from"
                  control={control}
                  rules={{
                    required: { message: 'Выберите дату начала', value: true },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <div className="flex">
                        <LMSDatePicker
                          selected={field.value ? new Date(field.value) : null}
                          locale={ru}
                          placeholderText="Дата начала"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          maxDate={maxDate ? new Date(maxDate) : undefined}
                          dateFormat="yyyy.MM.dd"
                          onChange={(date) =>
                            setValue('date_from', date ? formatDate(date as Date) : '')
                          }
                        />
                        <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
                      </div>
                      {error && <div className="text-red-500 mt-2">{error.message}</div>}{' '}
                    </div>
                  )}
                />
              </div>
              <div className="w-full flex flex-row new-lesson">
                <Controller
                  name="date_to"
                  control={control}
                  rules={{
                    required: { message: 'Выберите дату завершения', value: true },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <div className="flex">
                        <LMSDatePicker
                          selected={field.value ? new Date(field.value) : null}
                          locale={ru}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Дата завершения"
                          minDate={minDate ? new Date(minDate) : undefined}
                          dateFormat="yyyy.MM.dd"
                          onChange={(date) =>
                            setValue('date_to', date ? formatDate(date as Date) : '')
                          }
                        />
                        <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
                      </div>
                      {error && <div className="text-red-500 mt-2">{error.message}</div>}
                    </div>
                  )}
                />
              </div>
              <Controller
                name="has_certificate"
                control={control}
                rules={{
                  required: { message: 'Выберите наличие сертификата', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    className="text-white border-white"
                    title="Наличие сертификата"
                    value={hasCertificate === 1 ? 'Да' : hasCertificate === 0 ? 'Нет' : ''}
                    list={[
                      {
                        value: 1,
                        label: 'Да',
                      },
                      {
                        value: 0,
                        label: 'Нет',
                      },
                    ]}
                    error={error?.message}
                    onCourseClick={(id) => {
                      setValue('has_certificate', id);
                      setHasCertificate(id);
                    }}
                  />
                )}
              />
              <Controller
                name="price"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    return !isNaN(Number(value)) || 'Введите корректное число';
                  },
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      type="text"
                      baseViewPlaceholder
                      name="price"
                      placeholder="Стоимость курса"
                      value={String(value)}
                      error={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="currency"
                control={control}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Валюта"
                    className="text-white border-white"
                    value={currency}
                    list={currencies}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('currency', currencyShortNames[id]);
                      setCurrency(name);
                    }}
                  />
                )}
              />
              <Controller
                name="duration_in_hours"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    return !isNaN(Number(value)) || 'Введите корректное число';
                  },
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      type="text"
                      baseViewPlaceholder
                      name="duration_in_hours"
                      placeholder="Длительность курса (в часах)"
                      value={String(value)}
                      error={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="coaches"
                control={control}
                rules={{
                  required: !selectedCoaches.length && { message: 'Выберите тренера', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    className="text-white border-white"
                    title="Тренеры"
                    value={coachesString}
                    list={coaches}
                    error={error?.message}
                    onCourseClick={(id, name) => onChangeSelectedCoaches(id, name)}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-row gap-4 justify-end">
            <LMSButton
              isRed
              onClick={handleConfirmation}
              className="fixed bottom-0 left-0 right-0 py-4 rounded-none self-end sm:static sm:rounded-full"
            >
              УДАЛИТЬ
            </LMSButton>

            <LMSButton
              onClick={handleSubmit(onSubmit)}
              className="fixed bottom-0 left-0 right-0 py-4 rounded-none self-end sm:static sm:rounded-full"
            >
              СОХРАНИТЬ
            </LMSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
