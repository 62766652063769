import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { Props } from 'react-select';

import cl from './select.module.scss';

type SelectProps = Props;

interface CustomProps {
  label?: string;
  error?: string;
}

const LMSSelect: React.FC<SelectProps & CustomProps> = (props) => {
  const { t } = useTranslation();
  const [isFocused, setFocus] = useState(false);

  const onSelectFocus = () => {
    setFocus(true);
  };

  const onSelectBlur = () => {
    setFocus(false);
  };

  return (
    <div
      className={`${cl['select']} ${
        isFocused || props.value ? cl['select--focus'] : ''
      } theme-input`}
    >
      <ReactSelect
        noOptionsMessage={() => t('common.listEmpty')}
        {...props}
        placeholder={props.placeholder ? props.placeholder : ''}
        styles={{
          control: (base) => ({
            ...base,
            borderColor: props.error ? '#FF675E' : '#0004214D',
            borderRadius: '13px',
            padding: '9px',
            backgroundColor: 'transparent',
            userSelect: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            '&:hover': {
              borderWidth: '1px',
            },
          }),
          singleValue: (base, { isDisabled }) => ({
            ...base,
            color: isDisabled ? '#A9B5BB' : '#000000',
            backgroundColor: 'transparent',
          }),
          option: (base) => ({
            ...base,
            backgroundColor: 'theme-main-background',
            cursor: 'pointer',
            color: '#000000',
            '&:hover': {
              backgroundColor: '#F4F4F5',
            },
          }),
          input: (base) => ({
            ...base,
            cursor: 'none',
            pointerEvents: 'none',
            userSelect: 'none',
          }),
        }}
        onFocus={onSelectFocus}
        onBlur={onSelectBlur}
      />

      {props.error ? <p className={cl['select__error']}>{props.error}</p> : null}

      {props.label ? (
        <p className={`${cl['select__label']} ${props.error ? cl['select__label--error'] : ''}`}>
          {props.label}
          {props.required ? <span>*</span> : ''}
        </p>
      ) : null}
    </div>
  );
};

export default LMSSelect;
