import React from 'react';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time-fill.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { useAppSelector } from 'hooks/redux';

import AcademicPerformanceChart from '../../components/AcademicPerformanceChart';
import AttendanceChart from '../../components/AttendanceChart';
import DashboardFilter from '../../components/DashboardFilter';
import EfficiencyChart from '../../components/EfficiencyChart/EfficiencyChart';
import LearningTypeProgress from '../../components/LearningTypeProgress';
// import PlanFactChart from '../../components/PlanFactChart';
import SatisfactionChart from '../../components/SatisfactionChart';
import StudentTypeProgress from '../../components/StudentTypeProgress';
import TrainingFormatProgress from '../../components/TrainingFormatProgress';

const HrDashboard = () => {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div className="flex flex-col items-start">
      {/* <div className="w-full block lg:flex justify-stretch lg:justify-end mb-6 lg:mb-10"> */}
      <div className="w-full justify-stretch mb-6 lg:mb-10 hidden">
        <DashboardFilter />
      </div>

      <div className="flex-1 w-full">
        <div className="flex flex-col lg:grid grid-cols-2 gap-5">
          <div className="h-60 rounded-xl p-6 coach-bg">
            <p className="text-sm text-white/50">Добро пожаловать </p>
            <p className="mt-4 text-2xl">{user?.fio || ''}</p>
          </div>

          <AttendanceChart />

          <div className="flex items-center space-x-4 md:space-x-5 rounded-xl p-4 md:p-6 bg-studentCardBg">
            <div className="flex items-center justify-center w-10 h-10 shrink-0 rounded bg-blue">
              <LightningIcon className="w-3" />
            </div>
            <div className="flex-1 flex items-center justify-between">
              <div>
                <p>Вовлеченность</p>
                <p className="text-sm text-white/50">на 1 слушателя</p>
              </div>
              <div>
                <p className="text-xl md:text-2xl">85.5%</p>
                <p className="text-sm text-green">+12,7%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between md:space-x-5 space-y-4 md:space-y-0 mt-5">
          <EfficiencyChart />

          <AcademicPerformanceChart />

          <SatisfactionChart />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 items-center mt-5">
          <div className="flex items-center space-x-4 md:space-x-5 rounded-xl p-4 md:p-6 bg-studentCardBg">
            <div className="flex items-center justify-center w-10 h-10 shrink-0 rounded bg-blue">
              <TimeIcon className="w-5" />
            </div>
            <div className="flex-1 flex items-center justify-between">
              <div>
                <p>Среднее количество часов</p>
                <p className="text-sm text-white/50">на 1 слушателя</p>
              </div>
              <div>
                <p className="text-xl md:text-2xl text-right">4,37</p>
                <p className="text-sm text-green text-right">+1,34%</p>
                <p className="text-right text-[10px] text-white/50">с начала 2023</p>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 md:space-x-5 rounded-xl p-4 md:p-6 bg-studentCardBg">
            <div className="flex items-center justify-center w-10 h-10 shrink-0 rounded bg-blue">
              <WalletIcon />
            </div>
            <div className="flex-1 flex items-center justify-between">
              <div>
                <p>Среднее стоимость</p>
                <p className="text-sm text-white/50">на 1 слушателя</p>
              </div>
              <div>
                <p className="text-xl md:text-2xl text-right">19 309 ₸</p>
                <p className="text-sm text-error text-right">-34,8%</p>
                <p className="text-right text-[10px] text-white/50">с начала 2023</p>
              </div>
            </div>
          </div>
        </div>

        {/* <PlanFactChart /> */}

        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
          <TrainingFormatProgress />
          <StudentTypeProgress />
          <LearningTypeProgress />
        </div> */}
      </div>
    </div>
  );
};

export default HrDashboard;
