import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import { useThemeStatus } from 'hooks/theme';

import { fetchCourseById } from '../../api/repository/courses';
import ApplicationModal from '../../components/ApplicationModal';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { CommonCourse } from '../../entities/CommonCourse';

const CommonCourseDetail = () => {
  const { id } = useParams();
  const [query] = useSearchParams();

  const isLight = useThemeStatus(query);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isShowApplicationModal, setIsShowApplicationModal] = useState<boolean>(false);
  const [course, setCourse] = useState<CommonCourse | null>(null);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    fetchCourseById(+id)
      .then((res) => {
        setCourse(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-background--grey text-white text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <div
      className={`w-full min-h-[100vh] flex flex-col items-center pb-20 theme ${
        isLight && 'light-theme'
      }`}
    >
      <Header />
      {isLoading ? (
        <div className="mt-20">
          <Loading />
        </div>
      ) : null}

      {course && !isLoading ? (
        <div className="w-full md:max-w-[1360px] px-4 box-border mx-5 md:mx-[40px] my-[35px] md:my-[40px]">
          <div
            className="w-full h-full pt-[39px] rounded-[27px]"
            style={{
              backgroundImage: `url(${course.image_url || require('assets/images/course-bg.png')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="flex flex-col px-4 md:px-10 rounded-3xl theme-student-detail-course-card">
              <div className="flex items-center space-x-1 mb-[57px]">
                {course.price && (
                  <div className={badgeClass}>
                    {course.price} {course.currency}
                  </div>
                )}
                {course.direction ? <p className={badgeClass}>{course.direction}</p> : ''}
              </div>

              <Title className="!text-white font-[Raleway] font-semibold text-6xl leading-[58px] max-w-[618px] w-full">
                {course.name}
              </Title>

              <div className="flex flex-col-reverse md:flex-row items-stretch md:items-center justify-end mt-10 mb-10">
                <LMSButton
                  variant="solid"
                  colorType="main"
                  className="max-w-[288px] w-full"
                  onClick={() => setIsShowApplicationModal(true)}
                >
                  Записаться на курс
                </LMSButton>
              </div>
            </div>
          </div>

          {course.description && (
            <div className="mt-8 md:mt-14">
              <h2 className="font-[Raleway] font-medium text-[40px] leading-[30px]">
                Подробнее о курсе
              </h2>
              <div className="flex justify-between items-stretch rounded-[20px] w-full md:max-w-[1360px] bg-white mt-6 md:mt-10 theme-student-detail-course-card">
                <div
                  className="max-w-[903px] w-full p-[21px] text-[#434343] text-[24px]"
                  dangerouslySetInnerHTML={{ __html: course.description }}
                />
                <img
                  src={require('assets/images/grid.png')}
                  alt="grid"
                  className="max-w-[345px] h-auto lg:h-full object-cover object-center hidden lg:block"
                />
              </div>
            </div>
          )}
        </div>
      ) : null}

      {course && isShowApplicationModal ? (
        <ApplicationModal course={course.name} onClose={() => setIsShowApplicationModal(false)} />
      ) : null}
    </div>
  );
};

export default CommonCourseDetail;
