export const fileTypes = ['png', 'doc', 'excel', 'pdf', 'jpeg', 'jpg', 'webp'];
export const videoTypes = ['mov', 'wmv', 'mp4'];
export const presentationTypes = ['pptx', 'ppt', 'odp'];
export const MAX_VIDEO_SIZE_BYTES = 1024 * 1024 * 1024;

export const FileErrors = {
  wrongFormat: 'Неверный формат файла',
  fileSizeError: 'Превышен допустимый размер файла',
};

export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  [{ video: '' }],
  ['link'],
];

export const toolbarCourseOptions = [
  [{ font: [] }],
  ['bold', 'italic', 'underline'],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ color: [] }],
  [{ background: [] }],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
];
