import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import CourseManager from '../../components/CourseManager';
import CourseProgress from '../../components/CourseProgress';
import CourseScores from '../../components/CourseScore';

const ActiveCourseDetail = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const groupId = query.get('groupId') as string;

  if (!id) return <div></div>;
  return (
    <>
      <CourseManager />

      <CourseProgress id={+id} groupId={groupId} />

      <CourseScores id={+id} groupId={groupId} />
    </>
  );
};

export default ActiveCourseDetail;
