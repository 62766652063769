import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';
import { useThemeStatus } from 'hooks/theme';

interface Props {
  children: React.ReactNode;
  title: string;
}

const Accordion: React.FC<Props> = ({ children, title }) => {
  const [query] = useSearchParams();

  const isLight = useThemeStatus(query);
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div className="w-full rounded-2xl bg-studentCardBg">
      <button
        className="flex items-center justify-between w-full p-4 md:p-10"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className={`${!isLight && 'text-white'} text-xl md:text-2xl`}>{title}</span>
        <ArrowUpIcon className={`transition duration-500 ${isExpanded ? '' : 'rotate-180'}`} />
      </button>

      {isExpanded && (
        <div className={`${!isLight && 'text-white'} w-full px-4 pb-4 md:px-10 md:pb-10`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
