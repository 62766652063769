import { User } from 'entities/User';

import { UserDao } from '../dao/User.dao';

export function mapUserDaoToEntity(user: UserDao): User {
  return {
    id: user.id,
    email: user.email,
    fio: user.fio,
    phone: user.phone,
    occupation: user.occupation,
    roles: user.roles,
    currentRole: user.current_role,
    currentRoleId: +user.current_role_id,
    birthDate: user.birth_date,
    gender: user.gender,
    locationCity: user.location_city,
    locationCountry: user.location_country,
    avatar: user.avatar,
    company: user.company,
    holding: user.holding,
    division: user.division,
    project: user.project,
    personalType: user.personal_type,
    positionLevel: user.position_level,
    positionLevelTop: user.position_level_top,
    positionFamily: user.position_family,
    grade: user.grade,
    business: user.business,
    location_city_id: user.location_city_id,
    location_city: user.location_city,
    gender_id: user.gender_id,
    birth_date: user.birth_date,
  };
}
