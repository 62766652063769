import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchLessons } from 'modules/coach/api/repository/lessons';
import LessonCard from 'modules/coach/components/LessonCard';
import { INewLesson } from 'modules/coach/entities/Lessons';
import { useNotification } from 'hooks/notification';

import { fetchModules } from '../../api/repository/courses/modules';
import { fetchNewCoursesList } from '../../api/repository/courses-group-list';
import { ICourseMetaData } from '../../entities/CourseGroupList';

export const emptyIcon = 'assets/images/empty-image.png';

const Lessons = () => {
  const [Lessons, setLessons] = useState<INewLesson[]>([]);
  const [isLoading, setLoading] = useState(true);
  const notify = useNotification();
  const [courses, setCourses] = useState<ICourseMetaData[]>([]);
  const [modules, setModules] = useState<Record<string, string>[]>([]);
  const [courseId, setCourseId] = useState<number | undefined>(undefined);
  const [moduleId, setModuleId] = useState<number | undefined>(undefined);
  const getLessons = () => {
    fetchLessons({
      course_id: courseId || undefined,
      module_id: moduleId || undefined,
      by_this_coach: 1,
    })
      .then((res) => {
        setLessons(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getLessons();
    fetchNewCoursesList({}).then((data) => {
      setCourses(data);
    });
    fetchModules(undefined, undefined, true).then((res) => {
      setModules(res);
    });
  }, []);
  useEffect(() => {
    getLessons();
  }, [courseId, moduleId]);
  useEffect(() => {
    fetchModules(undefined, courseId, true).then((res) => {
      setModules(res);
    });
  }, [courseId]);
  return (
    <div className="w-full mt-20 lg:mt-0">
      <div className="flex flex-col pt-6">
        <div className="flex flex-row justify-between items-end">
          <LMSTitle tag="h4" className="mt-auto text-current text-3xl">
            Уроки
          </LMSTitle>
          <NavLink
            to="/coach/lessons/new-lesson"
            className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-3 px-6 text-[12px] font-bold uppercase rounded-[12px] md:relative md:rounded-[30px] theme-active-button"
          >
            <AddIcon />
            <span className="hidden md:block">Новый урок</span>
          </NavLink>
        </div>
        <form className="mt-4 flex">
          <div className="mr-2">
            <label
              htmlFor="course"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Выберите курс
            </label>
            <select
              id="course"
              value={courseId}
              onChange={(e) => {
                setCourseId(Number(e.target.value));
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={undefined}>Все</option>
              {courses.map((course) => {
                return (
                  <option key={course.id} value={course.id}>
                    {course.name}
                  </option>
                );
              })}
            </select>
          </div>
          {courseId ? (
            <div>
              <label
                htmlFor="course"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Выберите модуль
              </label>
              <select
                id="module"
                value={moduleId}
                onChange={(e) => {
                  setModuleId(Number(e.target.value));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={undefined}>Все</option>
                {modules.map((module) => {
                  return (
                    <option key={module.id} value={module.id}>
                      {module.title}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
        </form>
        <div className="w-full flex flex-col gap-y-4 pt-9">
          {isLoading && <Loader theme="dark" />}
          {Lessons ? (
            Lessons?.map((el) => {
              return <LessonCard key={el.id} data={el} />;
            })
          ) : (
            <div className="text-current">Созданные уроки отстуствуют</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Lessons;
