import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { useNotification } from 'hooks/notification';

import { IApplicationData } from '../../api/dao/Application.dao';
import { sendApplication } from '../../api/repository/application';

interface Props {
  course: string;
  onClose: () => void;
}

const ApplicationModal: React.FC<Props> = ({ course, onClose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const notify = useNotification();
  const { control, handleSubmit } = useForm<IApplicationData>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (values: IApplicationData) => {
    try {
      setIsLoading(true);
      console.log('values', values);
      await sendApplication({
        ...values,
        course_id: Number(id),
      });
      onClose();
      notify.success(t('landing.application.messages.success'));
    } catch {
      notify.error(t('landing.application.messages.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-10 min-w-full lg:min-w-[553px] max-h-[80vh] overflow-y-auto p-0 sm:p-4">
        <LMSTitle tag="h2">{t('landing.application.title')}</LMSTitle>

        <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-6">
          <Controller
            name="name"
            control={control}
            rules={{
              required: { message: t('error.required'), value: true },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                <input
                  type="text"
                  placeholder={t('landing.application.form.name')}
                  {...field}
                  className={`w-full h-[40px] flex items-center px-4 text-black border rounded-lg outline-none ${
                    error ? 'border-red-500' : 'border-[#D9D9D9]'
                  }`}
                />
                {error && <p className="text-red-500">{error.message}</p>}{' '}
              </div>
            )}
          />
          <Controller
            name="surname"
            control={control}
            rules={{
              required: { message: t('error.required'), value: true },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                <input
                  type="text"
                  placeholder={t('landing.application.form.surname')}
                  {...field}
                  className={`w-full h-[40px] flex items-center px-4 text-black border rounded-lg outline-none ${
                    error ? 'border-red-500' : 'border-[#D9D9D9]'
                  }`}
                />
                {error && <p className="text-red-500">{error.message}</p>}{' '}
              </div>
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: { message: t('error.required'), value: true },
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Неверный формат почты',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Email"
                  {...field}
                  className={`w-full h-[40px] flex items-center px-4 text-black border rounded-lg outline-none ${
                    error ? 'border-red-500' : 'border-[#D9D9D9]'
                  }`}
                />
                {error && <p className="text-red-500">{error.message}</p>}{' '}
              </div>
            )}
          />
        </form>

        <div className="flex flex-col lg:flex-row lg:justify-end">
          <LMSButton loading={isLoading} onClick={handleSubmit(onSubmit)}>
            {t('landing.application.form.submit')}
          </LMSButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default ApplicationModal;
