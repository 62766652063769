import React, { forwardRef, useState } from 'react';
import LMSInputMask from 'components/LMSInputMask';

import styles from './AuthInput.module.scss';

interface Props {
  name: string;
  label?: string;
  value: string;
  error?: string;
  type?: 'text' | 'password';
  mask?: string;
  required?: boolean;
  maxLength?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const AuthInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label = '',
      value,
      error,
      type = 'text',
      mask,
      required = false,
      maxLength = 0,
      onChange,
      onBlur = () => {},
      prefix,
      suffix,
    },
    ref
  ) => {
    const [isFocused, setFocus] = useState(true);

    const onInputBlur = () => {
      value ? setFocus(() => true) : setFocus(() => false);

      onBlur();
    };

    const onInputChange = (val: string) => {
      if (!!maxLength && val.length >= maxLength) {
        onChange(val.substring(0, maxLength));
      } else {
        onChange(val);
      }
    };

    return (
      <div className={`bg-transparent ${label ? 'pt-1.5' : ''}`}>
        <div className={`max-height-[46px] relative flex items-center`}>
          {label ? (
            <p
              className={`absolute left-4 px-1 text-base text-grey translate-y-[-50%] pointer-events-none transition-all duration-500 sm:text-lg`}
              style={{ top: isFocused ? '-16px' : '50%' }}
            >
              {label}
              {required && isFocused ? <span className="pl-1">*</span> : null}
            </p>
          ) : null}

          <div className="w-full flex items-center justify-between gap-1 p-4 bg-inputbg theme-text--default border theme-border--auth rounded-[14px]">
            {prefix ? prefix : null}
            {mask ? (
              <LMSInputMask
                id={name}
                type={type}
                mask={mask}
                value={value}
                className="w-full text-current outline-none theme-input"
                onChange={(event) => onInputChange(event.target.value)}
                onBlur={onInputBlur}
                onFocus={() => setFocus(true)}
              />
            ) : (
              <input
                ref={ref}
                id={name}
                type={type}
                value={value}
                className={`${styles.input} w-full text-current outline-none theme-input`}
                onChange={(event) => onInputChange(event.target.value)}
                onBlur={onInputBlur}
                onFocus={() => setFocus(true)}
              />
            )}
            {suffix ? suffix : null}
          </div>
        </div>

        {!!error ? (
          <div className="pt-1">
            <p className="text-[red]">{error}</p>
          </div>
        ) : null}
      </div>
    );
  }
);

export default AuthInput;
