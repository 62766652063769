import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/add-blue.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import LMSButton from 'components/LMSButton';
import LMSDatePicker from 'components/LMSDatePicker';
import LMSInput from 'components/LMSInput';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { toolbarCourseOptions } from 'modules/coach/constants';
import { createCourse } from 'modules/hr/api/repository/create-course';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { currencies, currencyShortNames } from '../../constants';
import { CourseFormData, INewCourseCoach } from '../../entities/Course';
import DropdownSelect from '../DropdownSelect';

const AddNewCourse = ({ coaches }: { coaches: Option[] }) => {
  const { control, handleSubmit, setValue, watch } = useForm<CourseFormData>();
  const notify = useNotification();
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState<File | null>();
  const [imagePreview, setImagePreview] = useState<string>();
  const [hasCertificate, setHasCertificate] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [selectedCoaches, setSelectedCoaches] = useState<Option[]>([]);
  const [minDate, setMinDate] = useState<string>('');
  const [maxDate, setMaxDate] = useState<string>('');

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const validateFormData = (formData: CourseFormData): string[] => {
    const errors: string[] = [];

    if (!imageFile) errors.push('Обложка курса обязательна');
    if (!formData.name?.trim()) errors.push('Название курса обязательно');
    if (!formData.description?.trim()) errors.push('Описание курса обязательно');
    if (!selectedCoaches.length) errors.push('Выберите хотя бы одного тренера');
    if (!formData.date_from) errors.push('Выберите дату начала');
    if (!formData.date_to) errors.push('Выберите дату завершения');
    if (
      formData.date_from &&
      formData.date_to &&
      new Date(formData.date_from) > new Date(formData.date_to)
    ) {
      errors.push('Дата начала не может быть позже даты завершения');
    }

    return errors;
  };

  const handleImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }, []);

  const onChangeSelectedCoaches = (id: number, name: string) => {
    const isCoachSelected = selectedCoaches.some((coach) => coach.value === id);
    const updatedCoaches = isCoachSelected
      ? selectedCoaches.filter((coach) => coach.value !== id)
      : [...selectedCoaches, { value: id, label: name }];

    setSelectedCoaches(updatedCoaches);

    const coachesArray: INewCourseCoach[] = updatedCoaches.map((coach) => ({
      coach_id: coach.value,
    }));

    setValue('coaches', coachesArray);
  };

  const onSubmit = async (data: CourseFormData) => {
    const formData = new FormData();

    try {
      const validationErrors = validateFormData(data);
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => notify.error(error));
        return;
      }

      if (imageFile) {
        formData.append('image_url', imageFile);
      }

      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (key === 'coaches' && Array.isArray(value)) {
            value.forEach((coach, index) => {
              Object.entries(coach).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${index}][${subKey}]`, String(subValue));
              });
            });
          } else if (typeof value === 'object' && !Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, String(value));
          }
        }
      });

      await createCourse(formData);

      notify.success('Курс успешно создан');

      await navigate('/hr/courses');
    } catch (err) {
      const error = err as ErrorResponse;
      console.log(error, 'error');
      notify.error('Не удалось создать курс, повторите еще раз или обратитесь в службу поддержки');
    }
  };

  const coachesString = useMemo(
    () => selectedCoaches.map((coach) => coach.label).join(', '),
    [selectedCoaches]
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'date_from') {
        setMinDate(value.date_from ? value.date_from.toString() : '');
      }
      if (name === 'date_to') {
        setMaxDate(value.date_to ? value.date_to.toString() : '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-white text-3xl">
          Создание курса
        </LMSTitle>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div
          style={{
            background:
              'linear-gradient(126.97deg,rgba(6, 11, 38, 0.89) 28.26%,rgba(26, 31, 55, 0.5)91.2%)',
          }}
          className="w-full flex flex-col gap-10 p-5 rounded-3xl sm:p-10"
        >
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5">Основные данные</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-4">
              <Controller
                name="image_url"
                control={control}
                rules={{
                  required: !imagePreview && { message: 'Добавьте обложку курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="row-span-3">
                    <div
                      className={`flex w-full h-full max-h-[219px] text-white font-bold bg-[#070b20] border border-0.5 border-dashed rounded-[14px] overflow-hidden outline-none ${
                        error ? 'border-red-500' : 'border-blue'
                      }`}
                    >
                      <label htmlFor="image" className="flex flex-1 cursor-pointer">
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="preview"
                            className="w-full h-full object-cover object-center"
                          />
                        ) : (
                          <div className="flex flex-col flex-1 items-center justify-center py-3 px-6">
                            <PlusIcon className="mb-4" />
                            <p>Добавить обложку курса</p>
                          </div>
                        )}
                      </label>
                      <input
                        id="image"
                        type="file"
                        accept="image/jpeg, image/png"
                        {...field}
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <p className="lg:hidden text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: 'Введите название курса', value: true },
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      name="name"
                      baseViewPlaceholder
                      placeholder="Название курса"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: { message: 'Введите описание курса', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <div className="flex flex-col relative">
                    <ReactQuill
                      placeholder={'Описание курса'}
                      modules={{
                        toolbar: toolbarCourseOptions,
                      }}
                      className=""
                      onChange={(data) => setValue('description', data)}
                    />
                    {error && (
                      <div className="text-red-500 absolute bottom-[-117px]">{error.message}</div>
                    )}
                  </div>
                )}
              />
            </div>

            <div className="hidden lg:grid grid-cols-2 gap-6">
              <p className="text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5 mt-[50px] md:mt-[40px] sm:mt-[100px]">
              Дополнительные данные
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 grid-rows-2 gap-6">
              <div className="w-full flex flex-row new-lesson">
                <Controller
                  name="date_from"
                  control={control}
                  rules={{
                    required: { message: 'Выберите дату начала', value: true },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <div className="flex">
                        <LMSDatePicker
                          selected={field.value ? new Date(field.value) : null}
                          locale={ru}
                          placeholderText="Дата начала"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          maxDate={maxDate ? new Date(maxDate) : undefined}
                          dateFormat="yyyy.MM.dd"
                          onChange={(date) =>
                            setValue('date_from', date ? formatDate(date as Date) : '')
                          }
                        />
                        <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
                      </div>
                      {error && <div className="text-red-500 mt-2">{error.message}</div>}
                    </div>
                  )}
                />
              </div>
              <div className="w-full flex flex-row new-lesson">
                <Controller
                  name="date_to"
                  control={control}
                  rules={{
                    required: { message: 'Выберите дату завершения', value: true },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <div className="flex">
                        <LMSDatePicker
                          selected={field.value ? new Date(field.value) : null}
                          locale={ru}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Дата завершения"
                          minDate={minDate ? new Date(minDate) : undefined}
                          dateFormat="yyyy.MM.dd"
                          onChange={(date) =>
                            setValue('date_to', date ? formatDate(date as Date) : '')
                          }
                        />
                        <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
                      </div>
                      {error && <div className="text-red-500 mt-2">{error.message}</div>}
                    </div>
                  )}
                />
              </div>
              <Controller
                name="has_certificate"
                control={control}
                rules={{
                  required: { message: 'Выберите наличие сертификата', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <div className="flex flex-col">
                    <DropdownSelect
                      notRounded={true}
                      title="Наличие сертификата"
                      className="text-white font-bold"
                      value={hasCertificate === 1 ? 'Да' : hasCertificate === 0 ? 'Нет' : ''}
                      list={[
                        {
                          value: 1,
                          label: 'Да',
                        },
                        {
                          value: 0,
                          label: 'Нет',
                        },
                      ]}
                      onCourseClick={(id) => {
                        setValue('has_certificate', id);
                        setHasCertificate(id);
                      }}
                    />
                    {error && <div className="text-red-500 mt-2">{error.message}</div>}
                  </div>
                )}
              />
              <Controller
                name="price"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      type="text"
                      baseViewPlaceholder
                      name="price"
                      placeholder="Стоимость курса"
                      value={value !== undefined ? String(value) : ''}
                      error={error?.message}
                      onChange={(val) => onChange(Number(val))}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="currency"
                control={control}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    className="text-white"
                    title="Валюта"
                    value={currency}
                    list={currencies}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('currency', currencyShortNames[id]);
                      setCurrency(name);
                    }}
                  />
                )}
              />
              <Controller
                name="duration_in_hours"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <LMSInput
                      baseViewPlaceholder
                      type="text"
                      name="duration_in_hours"
                      placeholder="Длительность курса (в часах)"
                      value={value !== undefined ? String(value) : ''}
                      error={error?.message}
                      onChange={(val) => onChange(Number(val))}
                      onBlur={onBlur}
                    />
                  </div>
                )}
              />
              <Controller
                name="coaches"
                control={control}
                rules={{
                  required: !selectedCoaches.length && { message: 'Выберите тренера', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Тренеры"
                    className="text-white font-bold"
                    value={coachesString}
                    list={coaches}
                    error={error?.message}
                    onCourseClick={(id, name) => onChangeSelectedCoaches(id, name)}
                  />
                )}
              />
            </div>
          </div>

          <LMSButton
            onClick={handleSubmit(onSubmit)}
            className="fixed bottom-0 left-0 right-0 py-4 rounded-none self-end sm:static sm:rounded-full"
          >
            СОЗДАТЬ КУРС
          </LMSButton>
        </div>
      </div>
    </div>
  );
};

export default AddNewCourse;
