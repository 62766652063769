import request from 'api/axios-instance';

import { CommonCourse } from '../../entities/CommonCourse';
import { CommonCourseDao } from '../dao/CommonCourse.dao';

export const fetchAllCourses = (params: Record<string, string | number | null>) =>
  request.get('v1/all-courses', { params }).then((res) => {
    const courses: CommonCourse[] = res.data.data.map(mapCommonCourseDaoToEntity);
    return {
      courses,
      lastPage: res.data.meta.last_page as number,
    };
  });

export const fetchCourseById = (id: number) =>
  request.get(`v1/course/${id}`).then((res) => mapCommonCourseDaoToEntity(res.data.data));

function mapCommonCourseDaoToEntity(course: CommonCourseDao): CommonCourse {
  function currency(currency: string): string {
    switch (currency) {
      case 'rub':
        return '₽';
      case 'usd':
        return '$';
      case 'eur':
        return '€';
      default:
        return '₸';
    }
  }

  return {
    id: course.id,
    name: course.name,
    description: course.description,
    programContent: course.program_content,
    image_url: course.image_url,
    courseType: course.course_type,
    studyType: course.study_type,
    price: course.price,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    direction: course.direction,
    currency: currency(course.currency),
  };
}
