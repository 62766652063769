import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import LMSButton from 'components/LMSButton';
import LMSDragDropContext from 'components/LMSDragDropContext';
import LMSDraggable from 'components/LMSDraggable';
import LMSDroppable from 'components/LMSDroppable';
import Loader from 'components/Loader';
import {
  deleteSimulation,
  fetchSimulation,
  postSimulationQuestions,
} from 'modules/coach/api/repository/simulations';
import AddOrEditSimulationQuestionModal from 'modules/coach/components/AddOrEditSimulationQuestionModal';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import CollapseSimulationDetail from '../../components/CollapseSimulationDetail';
import SimulationMainInfo from '../../components/SimulationDetail';
import SimulationQuestionCard from '../../components/SimulationQuestionCard';
import { ISimulationDetail, ISimulationQuestion } from '../../entities/Simulation';

const SimulationDetail = () => {
  const { id } = useParams();
  const notify = useNotification();
  const navigate = useNavigate();
  const { showConfirmBox } = useConfirmBox();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingButtons, setIsLoadingButtons] = useState<boolean>(false);
  const [simulation, setSimulation] = useState<ISimulationDetail>();
  const [questions, setQuestions] = useState<ISimulationQuestion[]>([]);
  const [question, setQuestion] = useState<ISimulationQuestion | null>(null);
  const [isShowAddOrEditSimulationQuestionModal, setIsShowAddOrEditSimulationQuestionModal] =
    useState<boolean>(false);

  const goNavigateBack = () => {
    navigate('/coach/simulations');
  };

  const handleConfirmation = () => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить симуляцию?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteSimulation(Number(id))
          .then(() => {
            goNavigateBack();
            notify.success('Симуляция успешно удалено!');
          })
          .catch(() => {
            notify.error('Произошла ошибка. Обратитесь в службу поддержки');
          });
      },
    });
  };

  const addNewQuestion = (question: ISimulationQuestion) => {
    if (questions.some((q) => q.id === question.id)) {
      setQuestions((prev) =>
        prev.map((q) => {
          if (q.id === question.id) {
            return question;
          }
          return q;
        })
      );
    } else {
      setQuestions((prev) => [...prev, { ...question, position: questions.length + 1 }]);
    }
  };

  const onDeleteQuestion = (id: number) => {
    const filteredQuestions = questions.filter((question) => question.id !== id);
    setQuestions(
      filteredQuestions.map((question, index) => ({ ...question, position: index + 1 }))
    );
  };

  const onQuestionOrderChange = (result: DropResult) => {
    if (!result.destination) return;

    const newQuestions = [...questions];
    const [reorderedQuestion] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, reorderedQuestion);
    setQuestions(newQuestions.map((question, index) => ({ ...question, position: index + 1 })));
  };

  const onSubmit = () => {
    if (!simulation?.id) {
      return;
    }

    if (!questions.length) {
      return notify.error('Невозможно сохранить изменения без вопросов');
    }

    setIsLoadingButtons(true);
    postSimulationQuestions(
      simulation.id,
      questions.map((question) => ({
        ...question,
        audio: question.audio
          ? Array.isArray(question.audio)
            ? question.audio[0].hash
            : question.audio
          : null,
      }))
    )
      .then(() => {
        notify.success('Изменения успешно сохранены!');
        goNavigateBack();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoadingButtons(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingButtons(true);
    fetchSimulation(Number(id))
      .then((res) => {
        setSimulation(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingButtons(false);
      });
  }, []);

  useEffect(() => {
    if (simulation?.id) {
      setQuestions(simulation.lectioQuestions);
    }
  }, [simulation]);

  return (
    <div className="mt-10 lg:mt-0 text-current">
      <div className="flex flex-col md:flex-row md:justify-between items-end md:mt-14 space-y-2 md:space-y-0 mt-4 mb-8">
        <button
          type="button"
          onClick={goNavigateBack}
          className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
        >
          <ArrowBack />
          Назад
        </button>

        <div className="flex flex-row gap-x-2">
          <LMSButton loading={isLoadingButtons} onClick={onSubmit}>
            Сохранить
          </LMSButton>
          <button
            onClick={handleConfirmation}
            className="w-[55px] h-[55px] flex justify-center items-center p-0 text-current theme-icon-stroke border theme-border--active bg-white bg-opacity-20 cursor-pointer rounded-full"
          >
            <TrashIcon />
          </button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {simulation && (
            <CollapseSimulationDetail title="Основные данные" simulationId={simulation.id}>
              <SimulationMainInfo simulation={simulation} />
            </CollapseSimulationDetail>
          )}

          <LMSDragDropContext onDragEnd={onQuestionOrderChange}>
            <LMSDroppable droppableId="questionsList">
              {(outerProvided) => (
                <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                  {questions.map((question, index) => (
                    <LMSDraggable
                      key={question.id}
                      draggableId={question.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div {...provided.draggableProps} ref={provided.innerRef}>
                          <SimulationQuestionCard
                            dragProps={provided.dragHandleProps}
                            question={{ ...question, position: index + 1 }}
                            onEdit={() => {
                              setQuestion(question);
                              setIsShowAddOrEditSimulationQuestionModal(true);
                            }}
                            onDelete={() => onDeleteQuestion(question.id)}
                          />
                        </div>
                      )}
                    </LMSDraggable>
                  ))}

                  {outerProvided.placeholder as React.ReactNode}
                </div>
              )}
            </LMSDroppable>
          </LMSDragDropContext>

          <button
            disabled={isLoadingButtons}
            className="flex items-center justify-center space-x-2 w-full mt-6 py-4 text-current theme-default-button--active rounded-lg theme-current"
            onClick={() => setIsShowAddOrEditSimulationQuestionModal(true)}
          >
            <span className="text-2xl">+</span>
            <span>ДОБАВИТЬ ЗАДАНИЕ</span>
          </button>
        </>
      )}

      {isShowAddOrEditSimulationQuestionModal && (
        <AddOrEditSimulationQuestionModal
          initialQuestion={question}
          onClose={() => {
            setIsShowAddOrEditSimulationQuestionModal(false);
            setQuestion(null);
          }}
          onChange={(question) => addNewQuestion(question)}
        />
      )}
    </div>
  );
};

export default SimulationDetail;
