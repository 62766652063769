import request from 'api/axios-instance';

import { Survey } from '../../entities/Survey';
import { SurveyDao } from '../dao/Survey.dao';

interface ProfileEditDto {
  location_city_id: number;
  phone: string;
}

export const editProfile = (data: ProfileEditDto) => request.patch('v1/auth/profile', data);

export const changeAvatar = (data: FormData) => request.post('v1/auth/profile/avatar', data);

export const fetchProfileGender = () => request.get('v1/gender');

export const fetchProfileCities = () => request.get('v2/cities');

export const fetchSurvey = (id: number, courseId: number) =>
  request
    .get(`v1/auth/profile/surveys/${id}`, { params: { course_id: courseId } })
    .then((res) => mapSurveyDaoToEntity(res.data.data));

interface SurveyAnswer {
  course_id: number;
  answers: { answer: number | string; question_id: number }[];
}

export const sendSurvey = (id: number, data: SurveyAnswer) =>
  request.post(`v1/auth/profile/surveys/${id}`, data);

function mapSurveyDaoToEntity(survey: SurveyDao): Survey {
  return {
    id: survey.id,
    name: survey.name,
    questions: survey.questions.map((question) => {
      if (question.answer_type === 'rating') {
        return {
          id: question.id,
          name: question.name,
          answerType: question.answer_type,
          options: question.options,
          selected: [],
        };
      }
      return {
        id: question.id,
        name: question.name,
        answerType: question.answer_type,
        selected: '',
      };
    }),
    course: survey.course,
  };
}
